import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Tab from './Tab'

const TabsList = styled.dl`
  margin-bottom: 24px;
`
const Content = styled.div`
  height: 100%;
`

const Tabs = ({children}) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label)

    return <>
        <TabsList>
            {children.map((child, index) => child && (
                <Tab
                    key={index}
                    activeTab={activeTab}
                    label={child.props.label}
                    onClick={() => setActiveTab(child.props.label)}
                />
            ))}
        </TabsList>
        <Content>
            {children.map((child) => (
                (child && child.props.label === activeTab) && child.props.children || undefined
            ))}
        </Content>
    </>
}

Tabs.propTypes = {
    titles: PropTypes.arrayOf(['string']),
    children: PropTypes.instanceOf(Array).isRequired,
}
export default Tabs
