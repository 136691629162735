import {ExplorerApi} from 'atomicassets'

class AtomicAssetsApi {
    constructor() {
        this.collection = 'numismatiums'
        this.coinsSchema = 'uscoins'
        this.packsSchema = 'coinpacks'
        //const fetch = window.fetch.bind(window);
/*
        const myFetch = async (input, init = {}) => {
            let noCorsInit = {}
            if (init) {
                noCorsInit = {...init}
            }
            noCorsInit['mode'] = 'no-cors'
            console.log(input)
            console.log(init)
            console.log(noCorsInit)
            let result = fetch(input, noCorsInit)
            console.log(await (await result).json())
            return result
        }
        this.api = new ExplorerApi('https://wax.api.atomicassets.io', 'atomicassets', {'fetch': myFetch})
*/
        this.api = new ExplorerApi('https://wax.api.atomicassets.io', 'atomicassets', {fetch})
        //this.api = new ExplorerApi('https://game.numismatium.com/proxy', 'atomicassets', {fetch})
    }

    async getAccountAssetsCount(name) {
        let account = await this.api.getAccount(name)
        let result = 0
        for (let i in account.collections) {
            if (account.collections[i].collection.collection_name === this.collection) {
                result = account.collections[i].assets
                break
            }
        }
        return result
    }

    async getAsset(id) {
        return await this.api.getAsset(id)
    }

    async getNSMCollections() {
        return await this.api.getSchemas({'collection_name': this.collection, 'match': 'coins'})
    }

    async getPacks() {
        return await this.api.getTemplates({'collection_name': this.collection, 'schema_name': this.packsSchema})
    }

    async getSchema(schemaName) {
        return await this.api.getSchema(this.collection, schemaName)
    }

    async getUserCoins(login) {
        let coins = []
        let page = 1
        let result = await this.api.getAssets({'owner': login, 'collection_name': this.collection, 'schema_name': this.coinsSchema}, page)
        while (result.length > 0) {
            coins.push(...result)
            page++
            try {
                result = await this.api.getAssets({
                    'owner': login,
                    'collection_name': this.collection,
                    'schema_name': this.coinsSchema
                }, page)
            } catch (e) {
                result = []
            }
        }
        return coins
    }
}

export default AtomicAssetsApi