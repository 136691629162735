import { configureStore } from '@reduxjs/toolkit'
import accountReducer from './accountSlice'
import appReducer from './appSlice'
/*
import canvasReducer from './components/canvas/canvasSlice'
import counterReducer from './features/counter/counterSlice'
import roadmapSlice from './components/modal/roadmapSlice'
*/

const store = configureStore({
  reducer: {
    account: accountReducer,
    app: appReducer,
/*
    canvas: canvasReducer,
    counter: counterReducer,
    roadmap: roadmapSlice,
*/
  },
})

export default store
