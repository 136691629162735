import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {palette, partiesParams} from '../../utils/constants'
import {useWindowDimensions} from '../../utils/helpers'
import {getFilterState, getShowSeats, setFilterState, setHoveredState} from '../../store/appSlice'

const Title = styled.text`
  display: none;
  stroke: #fff;

  &.black {
    stroke: #000;
  }
`
const StateSvg = styled.g`
  cursor: pointer;

  &.active {
    stroke: #fff;
    stroke-width: 2;
  }

  &.active ${Title} {
    display: block;
  }
`
const Seats = styled.text`
  fill: #fff;
  font-weight: 700;

  &.black {
    fill: #000;
  }
`

const State = (props) => {
    const filterState = useSelector(getFilterState)
    const isShowSeats = useSelector(getShowSeats)
    const {width} = useWindowDimensions()

    const dispatch = useDispatch()

    const hoverIn = () => {
        if (width > 991) {
            dispatch(setHoveredState(props.title))
        }
    }
    const hoverOut = () => {
        if (width > 991) {
            dispatch(setHoveredState(null))
        }
    }
    const setFilterStateHandler = () => {
        dispatch(setFilterState(props.title))
        if (width <= 991) {
            dispatch(setHoveredState(props.title))
        }
    }

    let color = palette.text
    if (props.votes) {
        let max = 0
        for (let key in props.votes) {
            if (props.votes[key] > max) {
                max = props.votes[key]
                color = partiesParams[key].hex
            }
        }
    }

    return <StateSvg
        className={(props.hoveredState && props.hoveredState.title === props.title || filterState === props.title) ? 'active' : ''}
        onMouseMove={hoverIn}
        onMouseOut={hoverOut}
        onClick={setFilterStateHandler}
    >
        <polygon
            points={props.points}
            fill={color}
        >
        </polygon>
        <Title
            x={props.titlePosition.x}
            y={props.titlePosition.y}
            strokeWidth={1}
            className={color === palette.text && 'black'}
        >
            {props.title}
        </Title>
        {isShowSeats && <Seats
            x={props.seatsPosition.x}
            y={props.seatsPosition.y}
            className={color === palette.text && 'black'}
        >
            {props.seats}
        </Seats>
        }
    </StateSvg>
}

State.propTypes = {
    fill: PropTypes.string,
    hoveredState: PropTypes.any,
    points: PropTypes.any,
    seatsPosition: PropTypes.any,
    seats: PropTypes.number,
    title: PropTypes.string,
    titlePosition: PropTypes.any,
    votes: PropTypes.object,
}
export default State
