import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette} from '../../utils/constants'
import {device, size} from '../../utils/breakpoints'
import {useWindowDimensions} from '../../utils/helpers'
import IconUser from '../../assets/images/iconUser.svg'
import IconExit from '../../assets/images/iconExit.svg'
import IconLogin from '../../assets/images/iconLogin.svg'
import ModalGameRules from '../modals/modalGameRules'
import ModalRoadmap from '../modals/modalRoadmap'

const Nav = styled.nav`
  align-self: center;
  @media ${device.laptop} {
    display: ${({expanded}) => expanded ? 'block' : 'none'};
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 2;

    background: ${palette.darkBlue};
    padding: 0;
    border-radius: 12px;
  }
`
const Menu = styled.ul`
  list-style: none;
  padding-left: 0;

  @media (max-width: 1460px) {
    padding: 0;
    margin: 0;
  }
`
const MenuItem = styled.li`
  margin-top: ${({line}) => line && '16px' || 0};

  a {
    cursor: pointer;
  }


  @media ${device.laptop} {
    display: block;
    margin-bottom: 5px;
    padding: 7px 30px;

    :hover {
      background: ${palette.blue};
    }
  }

  @media (min-width: ${+size.laptop + 1}px) {
    display: inline;

    &:not(:last-of-type) {
      margin-right: 24px;
    }
  }
`
const LogBtn = styled.button`
  color: ${palette.grey};
  font-size: 14px;
  display: flex;
  align-items: center;
`
const Icon = styled.div`
  margin-right: 10px;
  background: ${palette.blue};
  border-radius: 12px;
  width: 25px;
  height: 25px;
  line-height: 0;
  padding: 5px 5px;
  
  svg{
    rotate: ${({ isLogged }) => isLogged ? 0 : '180deg'};
    width: 100%;
  }
`

function Navigation(props) {
    const {width} = useWindowDimensions()
    const [rulesModalShow, setRulesModalShow] = useState(false)
    const [roadmapModalShow, setRoadmapModalShow] = useState(false)

    return <Nav expanded={props.expanded}>
        <Menu>
            <MenuItem>
                <a onClick={() => setRulesModalShow(true)}>Rules</a>
            </MenuItem>
            <MenuItem>
                <a href="https://numismatium.com" target='_blank' rel='noopener noreferrer'>Manage your packs</a>
            </MenuItem>
            <MenuItem>
                <a onClick={() => setRoadmapModalShow(true)}>Roadmap</a>
            </MenuItem>

            {width <= size.tablet && (
                props.ual.activeUser ?
                    <>
                        <MenuItem line>
                            <LogBtn>
                                <Icon>
                                    <img src={IconUser}/>
                                </Icon>
                                <span>{props.ual.activeUser.accountName}</span>
                            </LogBtn>
                        </MenuItem>
                        <MenuItem onClick={props.ual.logout}>
                            <LogBtn>
                                <Icon>
                                    <img src={IconExit} alt="exit"/>
                                </Icon>
                                <span>Log out</span>
                            </LogBtn>
                        </MenuItem>
                    </>
                    :
                    <MenuItem onClick={props.ual.showModal}>
                        <LogBtn>
                            <Icon>
                                <img src={IconLogin} alt="connect wallet"/>
                            </Icon>
                            <span>Log in</span>
                        </LogBtn>
                    </MenuItem>
            )}
        </Menu>

        <ModalGameRules show={rulesModalShow} toogleModal={setRulesModalShow}/>
        <ModalRoadmap show={roadmapModalShow} toogleModal={setRoadmapModalShow}/>
    </Nav>
}

Navigation.propTypes = {
    expanded: PropTypes.bool.isRequired,
    ual: PropTypes.object,
}
export default Navigation
