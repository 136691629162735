import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette, SLEEP_AFTER_TRANSACTION} from '../../utils/constants'
import iconCard from '../../assets/images/iconCard.svg'
import {device} from '../../utils/breakpoints'
import {
    getUserClaimBalance,
    getUserGameBalance,
    requestUserClaimBalance,
    requestUserGameBalance
} from '../../store/accountSlice'
import {sendingTransaction, sleep} from '../../utils/helpers'

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  @media ${device.tablet} {
    margin-right: 0;
    padding-right: 64px;
  }

  @media ${device.mobileL} {
    padding-right: 45px;
  }
`
const Icon = styled.span`
  background-color: ${palette.blueAccent};
  background-image: url(${iconCard});
  width: 31px;
  height: 31px;
  display: block;
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 15px;

  @media ${device.mobileL} {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 10px;
  }

  @media ${device.mobileM} {
    display: none;
  }
`
const Data = styled.div`
  > div:not(:last-child) {
    margin-bottom: 4px;
    @media ${device.mobilel} {
      margin-bottom: 2px;
    }
  }
`
const Label = styled.span`
  width: 57px;
  display: inline-block;
  text-decoration: ${props => (props.underline && 'underline') || 'none'};

  @media ${device.mobilel} {
    width: 50px;
  }
`
const Value = styled.span`
  font-weight: 700;
`
const Claim = styled.a`
  cursor: pointer;
`

const Balance = (props) => {
    const userClaimBalance = useSelector(getUserClaimBalance)
    const userGameBalance = useSelector(getUserGameBalance)

    const dispatch = useDispatch()

    const claim = () => {
        const tr = {
            actions: [{
                account: 'thehousegame',
                name: 'claim',
                authorization: [{
                    actor: props.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    username: props.activeUser.accountName,
                },
            }],
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestUserClaimBalance(props.activeUser.accountName))
            dispatch(requestUserGameBalance(props.activeUser.accountName))
        }
        sendingTransaction(tr, dispatch, props.activeUser, callback)
    }

    return <Block>
        <Icon/>
        <Data>
            <div>
                <Label>THC:</Label>
                <Value>{userGameBalance ? userGameBalance.split(' ')[0] : 0}</Value>
            </div>
            {userClaimBalance ?
                <Claim onClick={claim}>
                    <Label underline>Claim:</Label>
                    <Value>{userClaimBalance} THC</Value>
                </Claim>
                :
                null
            }
        </Data>
    </Block>
}

Balance.propTypes = {
    activeUser: PropTypes.object.isRequired,
}
export default Balance