import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette} from '../../utils/constants'

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Tip = styled.div`
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: ${palette.text};
  background: ${palette.darkGrey};
  font-size: 14px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;  

  ::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 2px;
    margin-left: calc(2px * -1);
  }
  &.top {
    top: calc(28px * -1);
  }
  
  &.top::before {
    top: 100%;
    border-top-color: ${palette.darkGrey};
  }
  
  &.right {
    left: calc(100% + 2px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  &.right::before {
    left: calc(2px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${palette.darkGrey};
  }
  &.bottom {
    bottom: calc(26px * -1);
  }
  
  &.bottom::before {
    bottom: 100%;
    border-bottom-color:${palette.darkGrey}
  }
  &.left {
    left: auto;
    right: calc(100% + 2px);
    top: 46%;
    transform: translateX(0) translateY(-50%);
  }

  &.left::before {
    left: auto;
    right: calc(2px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${palette.darkGrey};
  }

`;
function Tooltip({ children, delay, direction, content }) {
  const [active, setActive] = useState(false);
  let timeout;

  const showTip = () => {
    setTimeout(() => {
      setActive(true);
    }, delay || 20);
  }

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  }

  return (
    <Wrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {
        active && <Tip className={direction || 'top'}>
          {content}
        </Tip>
      }
    </Wrapper >
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  content: PropTypes.node,
  direction: PropTypes.oneOf(['top', 'right', 'left', 'bottom']),
}
export default Tooltip;