import React from 'react'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'
import {palette} from '../../utils/constants'
import {device} from '../../utils/breakpoints'
import {useSelector} from 'react-redux'
import {getNews} from '../../store/appSlice'
import TickerShort from './TickerShort'

const Container = styled.div`
  width: 100%;
  margin-right: 20px;
  background: ${palette.blue};
  padding: 8px 0;
  min-height: 42px;

  @media ${device.laptop} {
  }

  @media screen and (min-width: 1195px) {
    border: 1px solid #4F5981;
    border-radius: 15px;
  }

  @media screen and (min-width: 1750px) {
    margin-right: 45px;
  }


  @media ${device.tablet} {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    z-index: 1;
    max-width: 100%;
  }

  @media ${device.mobileL} {
    top: 52px;
  }
`
const List = styled.dl`
  display: flex;
`
const ListItem = styled.dt`
  margin-right: 16px;
`

function Ticker() {
    const news = useSelector(getNews)

    return <Container>
        <Marquee gradient={false}>
            <List>
                {news.length > 0 ? news.map(item => (
                    <ListItem key={item.asset_id}>
                        <TickerShort {...item} />
                    </ListItem>
                )) : null}
            </List>
        </Marquee>
    </Container>
}

export default Ticker