import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette} from '../../utils/constants'

import IconClose from '../../assets/images/iconClose.svg'

const Wrapper = styled.div`
  display: block;
`
const Tag = styled.div`
  background: #131D53;
  border: 1px solid ${palette.darkGrey};
  border-radius: 30px;
  padding: 7px 37px 7px 17px;
  display: inline-block;
  position: relative;
`
const Button = styled.button`
  position: absolute;
  right: 7px;
`

const TagLine = ({state, clearFilter}) => {
    if (!state) {
        return null
    }
    return <Wrapper>
        <Tag>
            <span>{state}</span>
            <Button onClick={clearFilter}>
                <img src={IconClose} alt="icon delete"/>
            </Button>
        </Tag>
    </Wrapper>
}

TagLine.propTypes = {
    state: PropTypes.string,
    clearFilter: PropTypes.func.isRequired,
}
export default TagLine
