import React, {useEffect, useState} from 'react'
import {createDfuseClient} from '@dfuse/client'
import styled from 'styled-components'
import {Route, Routes} from 'react-router-dom'
import GameLayout from './components/layouts/GameLayout'
import Game from './components/Game'
import NotFound from './components/NotFound'
import Header from './components/header/Header'
import {device} from './utils/breakpoints'
import {SLEEP_AFTER_TRANSACTION} from './utils/constants'
import {addNews, initialize, requestUsedCoin, updateAsset} from './store/appSlice'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import {requestUserClaimBalance, requestUserCoins, requestUserGameBalance} from './store/accountSlice'

import Background from './assets/images/background.png'
import IconLoading from './assets/images/loading.gif'

const BackPage = styled.div`
  background-color: rgb(7 12 47 / 95%);
  background-image: url(${Background});
  position: fixed;
  height: 100%;
  width: 100%;
  min-width: 300px;

  @media ${device.onlyDesktop} {
    padding: 28px;
  }
`
const Container = styled.div`
  max-width: 1920px;
  max-width: 100%;
  position: relative;
  height: 100%;

  @media ${device.tablet} {
    padding: 0;
  }
`
const LoadingIcon = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  img {
    margin: auto;
    width: 80px;
  }
`

const App = (props) => {
    const [accountName, setName] = useState(null)
    const [dfuse, setDfuse] = useState(null)

    const {loaded} = useSelector((state) => state.app)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(initialize())
    }, [])
    useEffect(() => {
        if (dfuse) {
            // обработка события transfer у контракта THC токенов
            const streamTransfer = `subscription($cursor: String!) {
  searchTransactionsForward(query: "receiver:thehousecoin action:transfer", cursor: $cursor) {
    undo cursor
    trace {
      matchingActions { json }
    }
  }
}`
            dfuse.graphql(streamTransfer, (message, stream) => {
                /*
                            if (message.type === "error") {
                                console.log("An error occurred", message.errors, message.terminal)
                            }
                */
                if (message.type === "data") {
                    const data = message.data.searchTransactionsForward
                    const actions = data.trace.matchingActions
                    actions.forEach(({json}) => {
                        //const { from, to, quantity, memo } = json
                        if (props.ual.activeUser && (
                            json.from === props.ual.activeUser.accountName ||
                            json.to === props.ual.activeUser.accountName
                        )) {
                            setTimeout(() => {
                                dispatch(requestUserGameBalance(props.ual.activeUser.accountName))
                            }, SLEEP_AFTER_TRANSACTION)
                        }
                    })
                    stream.mark({cursor: data.cursor})
                }
                /*
                            if (message.type === "complete") {
                                console.log("Stream completed")
                            }
                */
            })
            // обработка события claim в контракте игры
            const streamClaim = `subscription($cursor: String!) {
  searchTransactionsForward(query: "receiver:thehousegame action:claim", cursor: $cursor) {
    undo cursor
    trace {
      matchingActions { json }
    }
  }
}`
            dfuse.graphql(streamClaim, (message, stream) => {
                if (message.type === "data") {
                    const data = message.data.searchTransactionsForward
                    const actions = data.trace.matchingActions
                    actions.forEach(({json}) => {
                        if (props.ual.activeUser && json.username === props.ual.activeUser.accountName) {
                            setTimeout(() => {
                                dispatch(requestUserGameBalance(props.ual.activeUser.accountName))
                                dispatch(requestUserClaimBalance(props.ual.activeUser.accountName))
                            }, SLEEP_AFTER_TRANSACTION)
                        }
                    })
                    stream.mark({cursor: data.cursor})
                }
            })
            // обработка события selectcoin у контракта игры
            const streamSelectCoin = `subscription($cursor: String!) {
  searchTransactionsForward(query: "receiver:thehousegame action:selectcoin", cursor: $cursor) {
    undo cursor
    trace {
      matchingActions { json }
    }
  }
}`
            dfuse.graphql(streamSelectCoin, (message, stream) => {
                if (message.type === "data") {
                    const data = message.data.searchTransactionsForward
                    const actions = data.trace.matchingActions
                    actions.forEach(({json}) => {
                        setTimeout(() => {
                            dispatch(requestUsedCoin(json.asset_id))
                            dispatch(updateAsset(json.asset_id))
                            dispatch(addNews(json))
                        }, SLEEP_AFTER_TRANSACTION)
                    })
                    stream.mark({cursor: data.cursor})
                }
            })
        }
    }, [dfuse])
    useEffect(() => {
        if (loaded) {
            if (props.ual.activeUser && accountName !== props.ual.activeUser.accountName) {
                setName(props.ual.activeUser.accountName)
                setDfuse(createDfuseClient({
                    authentication: false,
                    network: 'wax.dfuse.eosnation.io',
                }))
                dispatch(requestUserGameBalance(props.ual.activeUser.accountName))
                dispatch(requestUserClaimBalance(props.ual.activeUser.accountName))
                dispatch(requestUserCoins(props.ual.activeUser.accountName))
                /*
                                dispatch(requestUserParty(props.ual.activeUser.accountName))
                */
            } else if (!props.ual.activeUser && accountName) {
                setName(null)
                dfuse.release()
                setDfuse(null)
//            props.resetUser()
            }

        }
    }, [props.ual.activeUser, loaded])

    return <BackPage>
        {loaded ?
            <Container>
                <Header ual={props.ual}/>
                <Routes>
                    <Route path="/" element={<GameLayout/>}>
                        <Route exact path="/" element={<Game/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </Container>
            :
            <LoadingIcon>
                <img src={IconLoading}/>
            </LoadingIcon>
        }
    </BackPage>
}

App.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(App)
