import {useState, useEffect} from 'react'

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const getStateName = (assetName) => {
    let stateName = assetName.trim()
    stateName = stateName.substring(0, stateName.indexOf(' quarter'))
    return stateName
}

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}
export const sendingTransaction = async (tr, dispatch, activeUser, callback) => {
    /*
      dispatch(setModalText('Signing transaction...'))
      dispatch(setModalType('loading'))
    */
    try {
        let result = await activeUser.signTransaction(tr, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 120,
        })
        if (result.status && result.status === "executed") {
            await callback()
//      dispatch(setModalType(null))
        } else {
            /*
                  dispatch(setModalError({name: 'Transaction error', message: result}))
                  dispatch(setModalType('error'))
            */
        }
    } catch (error) {
        /*
            dispatch(setModalError({name: error.name, message: error.message}))
            dispatch(setModalType('error'))
        */
    }
//  dispatch(setModalText(null))
}
