import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ModalWindow from './ModalWindow'
import Roadmap from './Roadmap'

const Header = styled.div`

`
const Title = styled.h3`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 32px;
`
const Body = styled.div`
  h4 {
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    margin-bottom: 20px;
  }

  ul {
    margin-bottom: 20px;
  }
`

function ModalRoadmap({show, toogleModal}) {
    return <ModalWindow
        onClose={() => toogleModal(false)}
        show={show}
    >
        <Header>
            <Title>Roadmap</Title>
        </Header>
        <Body>
            <Roadmap />
        </Body>
    </ModalWindow>
}

ModalRoadmap.propTypes = {
    show: PropTypes.bool.isRequired,
    toogleModal: PropTypes.func.isRequired,
}
export default ModalRoadmap