import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {withUAL} from 'ual-reactjs-renderer'
import TagLine from './TagLine'
import Toogler from './Toogler'
import {device, size} from '../../utils/breakpoints'
import {useDispatch, useSelector} from 'react-redux'
import {getFilterState, getUsers, requestUsers, setFilterState} from '../../store/appSlice'
import CoinShort from './CoinShort'
import CoinTitle from './CoinTitle'
import {getUserCoins} from '../../store/accountSlice'
import {partiesParams, SLEEP_AFTER_TRANSACTION} from '../../utils/constants'

import IconClose from '../../assets/images/iconClose.svg'
import {getStateName, sendingTransaction, sleep} from "../../utils/helpers";

const Block = styled.div`
  background: rgb(7, 12, 47);
  background: linear-gradient(156deg, rgba(7, 12, 47, 1) 42%, rgba(255, 255, 240, 0) 100%);
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 32px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    left: 0;
    padding: 100px 60px 50px;
    background: rgba(7, 12, 47, 0.6);
    backdrop-filter: blur(4px)
  }

  @media ${device.mobileL} {
    padding: 50px 20px 20px;
  }
`
const TextBlock = styled.div`
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 10px;
`
const Filter = styled.div`
  display: flex;
  margin-bottom: 32px;

  div:first-child {
    margin-right: 16px;
  }
`
const StateRow = styled.dt`
  padding: 7px 0;

  :hover {
    background: #131D53;
  }

  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`
const CloseBtn = styled.button`
  position: absolute;
  right: 60px;
  top: 70px;

  img {
    width: 35px;
  }

  @media ${device.onlyDesktop} {
    display: none;
  }

  @media ${device.mobileL} {
    right: 30px;
    top: 20px;
    img {
      width: 20px;
    }
  }
`
const List = styled.dl`
  overflow-y: auto;
  height: 65%;

  @media (max-width: ${size.tablet}px) and (min-width: ${size.mobileL}px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 70%;
  }
`

const Coins = (props) => {
    const [showUsed, setShowUsed] = useState(true)
    const filterState = useSelector(getFilterState)
    const userCoins = useSelector(getUserCoins)
    const users = useSelector(getUsers)

    const dispatch = useDispatch()

    const clearFilter = () => {
        dispatch(setFilterState(null))
    }
    const enterParty = () => {
        const tr = {
            actions: [{
                account: 'thehousegame',
                name: 'login',
                authorization: [{
                    actor: props.ual.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    username: props.ual.activeUser.accountName,
                    referrer: ''
                },
            }],
        }
        const callback = async () => {
            await sleep(SLEEP_AFTER_TRANSACTION)
            dispatch(requestUsers())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <Block>
        <TextBlock>
            <CoinTitle>Coins</CoinTitle>
            {props.ual.activeUser ?
                users[props.ual.activeUser.accountName] ?
                    <>
                        <CloseBtn onClick={() => props.closeMenu()}>
                            <img src={IconClose}/>
                        </CloseBtn>
                        <Filter>
                            <Toogler
                                label='Used'
                                checked={showUsed}
                                setChecked={setShowUsed}
                            />
                            {filterState && <TagLine state={filterState} clearFilter={clearFilter}/>}
                        </Filter>
                        <List>
                            {userCoins.map(item => {
                                if (!showUsed && item.used) {
                                    return null
                                }
                                if (filterState && getStateName(item.name) !== filterState) {
                                    return null
                                }
                                return <StateRow key={item.asset_id}>
                                    <CoinShort
                                        {...item}
                                        activeUser={props.ual.activeUser}
                                        color={partiesParams[users[item.owner]].color}
                                        size='large'/>
                                </StateRow>
                            })}
                        </List>
                    </>
                    :
                    <a
                        style={{cursor: "pointer", textDecoration: "underline"}}
                        onClick={enterParty}
                    >
                        You must to enter in the party
                    </a>
                :
                null
            }
        </TextBlock>
    </Block>
}

Coins.propTypes = {
    closeMenu: PropTypes.func,
    ual: PropTypes.object,
}
export default withUAL(Coins)
