import React from 'react'
import styled from 'styled-components'
import {palette} from '../../utils/constants'
import Tooltip from './Tooltip'
import {device} from '../../utils/breakpoints'
import {useDispatch, useSelector} from 'react-redux'
//import {setIsShowStateSeats} from '../canvas/canvasSlice'
import {getShowSeats, setShowSeats} from '../../store/appSlice'

import {ReactComponent as IconAlert} from '../../assets/images/iconAlert.svg'
import {ReactComponent as IconSeats} from '../../assets/images/iconSeats.svg'

const Block = styled.div`
  background: ${palette.darkBlue};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 16px;
  top: 0;

  @media ${device.tablet} {
    display: none;
  }
`
const Button = styled.button`
  line-height: 0;
  border-radius: 50%;
  box-shadow: ${({active}) => active ? '0px 0px 9px 1px rgba(50, 216, 226, 0.6)' : ''};

  &.m-bottom {
    margin-bottom: 8px;
  }
`

const Switches = () => {
    const isShowSeats = useSelector(getShowSeats)
    const dispatch = useDispatch()

    return <Block>
        <Tooltip content="it's this!" direction='left'>
            <Button className='m-bottom'>
                <IconAlert/>
            </Button>
        </Tooltip>

        <Tooltip content="Show seats" direction='bottom'>
            <Button active={isShowSeats} onClick={() => dispatch(setShowSeats(!isShowSeats))}>
                <IconSeats/>
            </Button>
        </Tooltip>
    </Block>
}

export default Switches
