import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {palette} from '../../utils/constants'
import IconClose from '../../assets/images/iconCloseCircle.svg'
import {device} from '../../utils/breakpoints'

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(7, 12, 47, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`
const Modal = styled.div`
  position: relative;
  background: ${palette.darkBlue};
  padding: 56px 40px;
  width: 1157px;
  max-width: 100%;
  overflow-y: auto;
  max-height: 78%;

  @media ${device.mobileL} {
    height: 100%;
    max-height: 100%;
  }
`
const ButtonClose = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  background-image: url(${IconClose});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 28px;
  height: 28px;
`

const ModalWindow = ({show, onClose, children}) => {
    if (!show) return null

    return <Overlay onClick={onClose}>
        <Modal onClick={(e) => e.stopPropagation()}>
            <ButtonClose onClick={onClose}></ButtonClose>
            {children}
        </Modal>
    </Overlay>
}
ModalWindow.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.instanceOf(Array).isRequired,
}
export default ModalWindow
