import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ModalWindow from './ModalWindow'


const language = ['En', 'Rus']

const Header = styled.div`

`
const Title = styled.h3`
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 32px;
`
const ListLng = styled.dl`
  dt {
    display: inline-block;
  }
`
const ItemLng = styled.dt`
  margin-right: 8px;
`
const ButtonLng = styled.button`
  color: ${({active}) => active ? '#fff ' : 'rgba(255,255,255, .3)'};
`
const Body = styled.div`
  h4 {
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  ul {
    margin-bottom: 20px;
  }
`

const ModalGameRules = ({show, toogleModal}) => {
    const [activeLng, setLanguage] = useState(language[0])

    return <ModalWindow
            onClose={() => toogleModal(false)}
            show={show}
        >
            <Header>
                <ListLng>
                    {
                        language && language.map((ln, index) => (
                            <ItemLng
                                onClick={() => setLanguage(ln)}
                                key={index}
                            >
                                <ButtonLng active={activeLng === ln}>{ln}</ButtonLng>
                            </ItemLng>
                        ))
                    }
                </ListLng>
                <Title>Game rules</Title>
            </Header>
            {activeLng === language[0] &&
                <Body>
                    <h4>Object</h4>
                    <p>The object of the game is to win the elections to the United States House of Representatives (the
                        House)
                        for your party by winning as many seats as possible on the ballot in each state.</p>

                    <h4>Description</h4>
                    <p>Every 30 hours there are elections for 435 members of the House of Representatives. Each state
                        has a
                        certain number of seats in the House, from 1 to 52. The parties running for elections try to win
                        the
                        election in each state in order to get a majority in the House.</p>
                    <p> To run in a state, party members have to invest in an advertising campaign.This is done with
                        special
                        coins, quarters, that are unique to each state.They are not spent during the game, remaining the
                        player&apos;s property, but can be used only once in a voting round. Each quarter used in a
                        voting round
                        earns a certain number of electoral votes. The seats belonging to a state in the House of
                        Representatives
                        are distributed among the parties in proportion to the votes received in that state.</p>
                    <p> Following the results of the election, the House of Representatives is formed, and the prize
                        fund is
                        distributed among the parties that entered it.After that, each party&apos;s share is
                        automatically divided
                        among its members in proportion to their contribution to the result.</p>

                    <h4>Quarters</h4>
                    <p> Numismatiums are collectible NFTs featuring the United States collector coins worth 25 cents.
                        Each state
                        has its own unique quarters of different quality and value in the game:</p>
                    <ul>
                        <li>XF – 1 vote</li>
                        <li>XF Shine – 4 votes</li>
                        <li>UNC Shine – 16 votes</li>
                        <li>PROOF – 16 votes</li>
                        <li>PROOF Shine – 64 votes</li>
                    </ul>
                    <p>Each quarter is unique, having its own ID in the WAX blockchain and the following settings: a
                        state name, quality and a mint number. For example, Texas UNC 6 is a quarter with an image
                        dedicated to the state of Texas, which is of UNC quality and number 6 by issue.</p>

                    <p>Quarters can be sold, bought and transferred between accounts as a unique non-fungible token in
                        the WAX blockchain. Quarters are not spent during the game, they remain the property of the
                        player until he decides to transfer them to another account (e.g., sell).</p>

                    <p>You can buy specific quarters on the open market on AtomicHub (wax.atomichub.io). On our website
                        numismatium.com, you can buy packs with random quarters. The probability of finding a quarter of
                        a certain quality in a pack is inversely proportional to its value.</p>

                    <h4>Parties</h4>

                    <p>Unlike real U.S. elections, The House involves five parties: Libertarian, Republican, Сentrist,
                        Democratic and Socialist. The player has the right to join any party, as well as change the
                        party affiliation between elections.</p>

                    <h4>The Play</h4>

                    <p>When first logging in the game, the player automatically joins the party with the lowest number
                        of members. The player has the right to change the party, but the changes will come into force
                        only after the voting round is over.</p>

                    <p>To help his party get delegates from a particular state to Congress, the player invests that
                        state&apos;s quarters in the election, attracting votes. The object is to get as many votes as
                        possible in that state by the time the results are announced.</p>

                    <h4>Results and winnings</h4>

                    <p>Every 30 hours a round of elections is completed. Each state&apos;s seats in the United States House
                        of Representatives are distributed proportionally among the parties running in that state. If a
                        party gets out more than 50% of the votes in a state, it takes all the seats.</p>

                    <div style={{margin: '0px 0px 10px 50px'}}><i><p>Example: There are eight seats in the state. The votes
                        were distributed as follows: Republican Party 35%, Democratic Party 30%, Libertarian Party 25%,
                        Centrist Party 5%, Socialist Party 5%. Then:</p>
                        <ul>
                            <li>Republican Party gets 8 * 35% = 2 seats and 0.8 remainder,</li>
                            <li>Democratic Party gets 8 * 30% = 2 seats and 0.4 remainder,</li>
                            <li>Libertarian Party gets 8 * 25% = 2 seats,</li>
                            <li>Centrist and Socialist Parties get 8 * 5% = no seats and 0.4 remainder.</li>
                        </ul>
                        <p>Six seats are distributed. Of the remaining two seats, one goes to the Republican Party (they
                            have a larger remainder), and the other gets one of the parties with a remainder of 0.4,
                            which put a coin with a smaller ID (asset_id) in the WAX blockchain.</p></i></div>
                    <p>When the round is completed, THC tokens (TheHouseCoin) are distributed among all the players
                        depending on the number of seats taken by the party and on the contribution of a player in a
                        particular state. Ten THCs are given for each seat.</p>

                    <p>Quarters which have received THCs are not allowed to participate in the following rounds,
                        depending on their rarity: XF – 1 round, XF Shine and UNC – 2 rounds, UNC Shine and PROOF – 3
                        rounds, PROOF Shine – 4 rounds.</p>

                    <p>Also, at the end of each round, new coin packs are issued and put up for auction with a minimum
                        price of 0.01 WAX. The number of packs are equal to 0.25% of the total number of issued
                        quarters, rounded up.</p>
                </Body>
            }
            {activeLng === language[1] &&
                <Body>
                    <p><b>Цель игры</b></p>
                    <p>Выиграть выборы в Палату представителей Конгресса США (The House) для своей партии, получив как
                        можно
                        больше мест при голосовании в каждом штате.</p>
                    <p><b>История</b></p>
                    <p>Каждые 30 часов проходят выборы 435-ти депутатов Палаты представителей. Каждый штат имеет
                        определённое количество мест в Палате - от 1 до 52. Партии, участвующие в выборах, стараются
                        выиграть выборы в каждом штате, чтобы получить большинство в Палате.</p>
                    <p>Для участия в выборах в штате членам партии необходимо «вложить средства в рекламную кампанию».
                        Это
                        делается с помощью специальных монет - квотеров - уникальных для каждого штата. Они не тратятся
                        в
                        ходе игры, оставаясь в распоряжении владельца, но могут быть использованы в игре только один раз
                        в
                        раунде голосования. Каждый использованный в раунде голосования квотер приносит определённое
                        количество голосов избирателей (см. ниже). Принадлежащие штату места депутатов в Палате по
                        итогам
                        выборов распределяются между партиями пропорционально полученным голосам в этом штате.</p>
                    <p>По итогам выборов формируется состав Палаты представителей, и между вошедшими в неё партиями
                        распределяется призовой фонд. После этого доля каждой партии автоматически разделяется среди её
                        членов пропорционально их вкладу в результат.</p>
                    <p><b>Квотеры</b></p>
                    <p>Квотеры из коллекции Numismatiums - это коллекционные NFT, изображающие 25-центовые коллекционные
                        монеты, выпускавшиеся в США. Каждый штат имеет свои уникальные квотеры, выпущенные в разном
                        качестве
                        и, соответственно, разной редкости и разной ценности в игре:</p>
                    <ul>
                        <li>XF - 1 голос избирателей</li>
                        <li>XF Shine - 4 голоса</li>
                        <li>UNC - 4 голоса</li>
                        <li>UNC Shine - 16 голосов</li>
                        <li>PROOF - 16 голосов</li>
                        <li>PROOF Shine - 64 голоса</li>
                    </ul>
                    <p>Каждый квотер уникален, он имеет собственный ID в блокчейне WAX и следующие параметры: название
                        штата, качество и номер минта. Например, «Texas UNC 6» - это квотер с изображением, посвященным
                        штату Техас, имеющий качество UNC и номер 6 по порядку выпуска.</p>
                    <p>Квотеры могут продаваться, покупаться и передаваться между аккаунтами как уникальный
                        невзаимозаменяемый токен в блокчейне WAX. Квотеры во время игры не тратятся, они остаются в
                        собственности игрока до тех пор, пока он не решит их передать на другой аккаунт (например,
                        продать).</p>
                    <p>Приобрести конкретные квотеры можно на открытом рынке на сайте AtomicHub (wax.atomichub.io). У
                        нас на
                        сайте numismatium.com можно купить паки со случайными квотерами. При этом вероятность нахождения
                        квотера определённого качества в паке обратно пропорциональна его ценности.</p>
                    <p><b>Партии</b></p>
                    <p>В отличие от реальных выборов в США, в игре The House участвуют пять партий: либертарианцы,
                        республиканцы, центристы, демократы и социалисты. Игрок имеет право вступить в любую партию, а
                        также
                        поменять партийную принадлежность между выборами.</p>
                    <p><b>Порядок игры</b></p>
                    <p>После первого входа в игру игрок автоматически вступает в партию с наименьшим числом членов.
                        Игрок
                        вправе сменить партию, но изменения вступят в силу только после окончания раунда
                        голосования.</p>
                    <p>Чтобы помочь своей партии провести в Конгресс делегатов от конкретного штата, игрок инвестирует в
                        выборы квотеры этого штата, привлекая голоса избирателей. Задача - чтобы к моменту подсчёта
                        результатов партия игрока получила как можно больше голосов избирателей в данном штате.</p>
                    <p><b>Подсчёт результатов и выплата выигрыша</b></p>
                    <p>Каждые 30 часов завершается раунд выборов. Депутатские места каждого штата в Палате
                        представителей
                        Конгресса США пропорционально распределяются между партиями, участвовавшими в выборах в данном
                        штате. Если партия имеет более 50% голосов в штате, то она забирает все места в штате.</p>
                    <div style={{margin: '0 0 10px 50px'}}><i><p>Пример. В штате 8 мест. Голоса распределились следующим
                        образом: республиканцы - 35%,
                        демократы -
                        30%, либертарианцы - 25%, центристы - 5%, социалисты - 5%. Тогда:</p>
                        <ul>
                            <li>республиканцы получают 8 * 35% = 2 места и 0,8 остаток,</li>
                            <li>демократы - 8 * 30% = 2 места и 0,4 остаток,</li>
                            <li>либертарианцы 8 * 25% = 2 места,</li>
                            <li>центристы - 8 * 5% = 0 мест и 0,4 остаток,</li>
                            <li>социалисты - 8 * 5% = 0 мест и 0,4 остаток.</li>
                        </ul>
                        <p>Распределено 6 мест, из оставшихся двух мест одно достается республиканцам (у них больше
                            остаток), а
                            второе получает одна из партий с остатком 0,4, поставившая монету с меньшим ID (asset_id) в
                            блокчейне WAX.</p></i></div>
                    <p>После завершения раунда среди всех игроков распределяются токены THC (TheHouseCoin) в зависимости
                        от
                        числа мест, взятых партией, и от вклада конкретного игрока в конкретном штате. На каждое место
                        выделяется 10 THC.</p>
                    <p>Квотеры, получившие THC по итогам голосования, блокируются для участия в следующих раундах в
                        зависимости от их редкости: XF – 1 раунд; XF Shine и UNC – 2 раунда; UNC Shine и PROOF – 3
                        раунда;
                        PROOF Shine – 4 раунда.</p>
                    <p>Также после окончания каждого раунда выпускаются новые паки с монетами и выставляются на аукцион
                        с
                        минимальной ценой в 0,01 WAX. Число паков равно 0,25% от общего числа выпущенных квотеров с
                        округлением в большую сторону.</p>
                </Body>
            }
        </ModalWindow>
}

ModalGameRules.propTypes = {
    show: PropTypes.bool.isRequired,
    toogleModal: PropTypes.func.isRequired,
}
export default ModalGameRules
