import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {palette} from '../../utils/constants'
import {ReactComponent as CheckIcon} from '../../assets/images/check.svg'

const tasks = {
    2021: [
        {
            title: 'Site for collection',
            status: 'ready',
            link: 'https://numismatium.com',
        },
        {
            title: 'Game smart contract',
            status: 'ready',
            desc: 'thehousegame',
        },
        {
            title: 'Game MVP',
            status: 'ready',
            desc: 'The first version of the interface for the beta test.'
        },
    ],
    2022: [
        {
            title: 'New design',
            status: 'ready',
            desc: 'New game interface.',
        },
        {
            title: 'Pack minting',
            status: 'ready',
            desc: 'Minting new packs after the end of each round.',
        },
    ],
    2023: [
        {
            title: 'Marketplace',
            status: 'ready',
            desc: 'Putting new packs up for auction.',
            link: 'https://numismatium.com/auctions',
            linkTitle: 'See',
        },
        {
            title: 'THC/WAX pool',
            status: 'progress',
            desc: 'Own smart contract'
        },
        {
            title: 'Mini game',
            status: 'progress',
            desc: 'Mini game with THC token'
        },
        {
            title: 'THC listing',
            status: 'soon',
            desc: ''
        },
    ],
}
const IconWrap = styled.div`
  line-height: 0;
`
const Task = styled.dt`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 0 20px;

  div${IconWrap} {
    svg {
      fill: ${({status}) => status != 'ready' ? status != 'progress' ? palette.grey : palette.blueAccent : palette.green};
      width: 100%;
    }
  }
`
const Title = styled.h5`
  align-self: center;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 16px;
`
const Description = styled.p`
  margin-bottom: 0;
`
const Line = styled.hr`
  border-color: ${({status}) => status != 'ready' ? status != 'progress' ? palette.grey : palette.blueAccent : palette.green};;
`

const TaskList = (props) => {
    return <dl>
        {
            props.tasks && props.tasks.map((t, index) => (
                <Task key={index} status={t.status} aria-label={`Task in ${t.status} status`}>
                    <IconWrap>
                        <CheckIcon/>
                    </IconWrap>
                    <Title>{t.title}</Title>
                    <Line status={t.status}/>
                    <Description>
                        {t.desc || null}
                        {' '}
                        {t.link ?
                            <a
                                href={t.link}
                                target={'_blank'}
                                rel={'noreferrer'}
                                style={{textDecoration: 'underline'}}
                            >
                                {t.linkTitle || t.link}
                            </a>
                            :
                            null
                        }
                    </Description>
                </Task>
            ))
        }
    </dl>
}
TaskList.propTypes = {
    tasks: PropTypes.array,
}

const Roadmap = () => {
    return <dl>
        {tasks && Object.keys(tasks).map((item) => (
            <React.Fragment key={item}>
                <div style={{margin: '15px'}}>
                    <Title>{item}</Title>
                </div>
                <TaskList tasks={tasks[item]}/>
            </React.Fragment>
        ))
        }
    </dl>
}
export default Roadmap
