export const size = {
  mobileS: '320',
  mobileM: '375',
  mobileL: '600',
  tablet: '1194',
  laptop: '1440',
  desktop: '2560'
};

export const device = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  desktop: `(max-width: ${size.desktop}px)`,
  onlyDesktop: `(min-width: ${+size.tablet + 1}px)`,
};