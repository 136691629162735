import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {device, size} from '../../utils/breakpoints'
import {useWindowDimensions} from '../../utils/helpers'
import Coins from './Coins'
import News from './News'

const Block = styled.aside`
  @media ${device.onlyDesktop} {
    position: relative;
    width: 330px;
    display: grid;
    grid-template-rows: 60% 40%;
    padding-bottom: 28px;
  }
`

const Panels = ({isCoinOpen, isNewsOpen, closeMenu}) => {
    const {width} = useWindowDimensions()

    return <Block>
        {((width > size.tablet) || isCoinOpen) && <Coins closeMenu={closeMenu}/>}
        {((width > size.tablet) || isNewsOpen) && <News closeMenu={closeMenu}/>}
    </Block>
}

Panels.propTypes = {
    isCoinOpen: PropTypes.bool,
    isNewsOpen: PropTypes.bool,
    closeMenu: PropTypes.func
}
export default Panels
