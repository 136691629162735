import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {palette} from '../../utils/constants'
import {device} from '../../utils/breakpoints'
import Score from './Score'
import {getTotalVotes} from '../../store/appSlice'

const Block = styled.div`
  padding: 35px 24px;
  background: ${palette.darkBlue};
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: 28px;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;

  @media ${device.laptop} {
    width: 330px;
    padding: 16px 0;
  }

  @media ${device.tablet} {
    bottom: 0;
  }

  @media screen and (max-width: 780px) {
    width: 310px;
    left: 0;
    padding: 12px 0;
    transform: translateX(0);
  }

  @media screen and (max-width: 400px) {
    width: 250px;
  }
`
const Scoreboard = () => {
    const totalVotes = useSelector(getTotalVotes)

    return <Block>
{/*
        <Score key={'text'} text={'TEXT'} value={'346353'}/>
        <Score key={'time'} text={'Time'} value={'12:23:34'}/>
*/}
        <Score key={'votes'} text={'Total votes'} value={totalVotes}/>
    </Block>
}

export default Scoreboard
