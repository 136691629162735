import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette, partiesParams} from '../../utils/constants'
import {device} from '../../utils/breakpoints'
import IconClose from '../../assets/images/iconCloseCircle.svg'
import {useDispatch} from 'react-redux'
import {setHoveredState} from '../../store/appSlice'

const Block = styled.div`
  background: #131D53;
  padding: 16px 25px 16px;
  border-radius: 16px;
  position: absolute;
  bottom: 28px;

  @media ${device.onlyDesktop} {
    right: 16px;
  }

  @media ${device.tablet} {
    left: 0;
    bottom: 0;
  }

  @media screen and (max-width: 780px) {
    bottom: 80px;
    left: 0;
  }

  @media screen and (max-width: 400px) {
    bottom: 66px;
  }
`
const Heading = styled.h3`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: ${props => props.mBottom || 8}px;
`
const List = styled.dl`
  :not(:last-of-type) {
    margin-bottom: 5px;
  }
`
const ListItem = styled.div`
  display: grid;
  grid-template-columns: 150px auto;

  @media screen and (max-width: 870px) {
    grid-template-columns: 140px auto;
  }

  :not(:last-child) {
    margin-bottom: 5px;
  }

  dt {
    text-transform: capitalize;
    font-size: 14px;
    color: ${palette.grey};

    &::after {
      content: ': ';
    }
  }


  dt, dd {
    font-size: 14px;
    display: inline;
  }

  dd {
    font-weight: 700;

    @media ${device.tablet} {
      margin-left: 10px;
    }
  }
`
const Button = styled.button`
  color: ${palette.text};
  text-decoration: underline;
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;

  @media ${device.onlyDesktop} {
    display: none;
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
    margin-top: 10px;
  }
`
const CloseBtn = styled.button`
  position: absolute;
  right: 9px;
  top: 9px;


  @media ${device.onlyDesktop} {
    display: none;
  }
`

const StateInfo = (props) => {
    const dispatch = useDispatch()

    const closeStateInfo = () => {
        dispatch(setHoveredState(null))
    }

    let arrayVotes = []
    if (props.votes) {
        for (let key in props.votes) {
            arrayVotes.push({title: partiesParams[key].title, value: props.votes[key]})
        }
    }

    return <Block>
        <Heading>State info</Heading>
        <CloseBtn onClick={closeStateInfo}>
            <img src={IconClose}/>
        </CloseBtn>

        <List>
            <ListItem>
                <dt>Seats in the house</dt>
                <dd>{props.seats}</dd>
            </ListItem>
        </List>

        <Heading mBottom={5}>Votes</Heading>
        {arrayVotes.length > 0 ?
            <List>
                {arrayVotes.map(({title, value}) => (
                    <ListItem key={title}>
                        <dt>{title}</dt>
                        <dd>{value}</dd>
                    </ListItem>
                ))}
            </List>
            :
            'no votes'
        }
        <Button>Show state coins</Button>
    </Block>
}

StateInfo.propTypes = {
    seats: PropTypes.number,
    votes: PropTypes.any,
}
export default StateInfo
