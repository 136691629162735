import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {borderLeft, topMenuHeight, borderTop, gameWidth, borderRight, gameHeight, borderBottom} from '../../utils/constants'
import State from './State'
import {countVotes, getHoveredState, getStates} from '../../store/appSlice'

const SVG = styled.svg`
  @media ${device.tablet} {
    width: 1000px;
  }

  @media ${device.mobileL} {
    width: 800px;
  }
`

const Canvas = () => {
    const statesList = useSelector(getStates)
    const hoveredState = useSelector(getHoveredState)
    const viewBox = [-borderLeft, -borderTop, gameWidth + borderLeft + borderRight, gameHeight + borderTop + borderBottom]

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(countVotes())
    }, [])

    const svgStyle = {
        maxHeight: `${(window.innerHeight - topMenuHeight)}px`,
    }

    const map = useMemo(() => {
        let result = []
        for (let key in statesList) {
            result.push(<State
                key={key}
                {...statesList[key]}
                hoveredState={hoveredState}
            />)
        }
        return result
    }, [statesList, hoveredState])

    return <SVG
        id="the-house-canvas"
        viewBox={viewBox}
        style={svgStyle}
    >
        {map}
    </SVG>
}

export default Canvas
