import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {palette, pointsPerRarity} from '../../utils/constants'
import {setCoinImage, setHoveredState} from '../../store/appSlice'
import {getStateName, sendingTransaction} from '../../utils/helpers'

const NumberLabel = styled.div`
  border: ${props => `1px solid ${palette[props.color]}`};
  border-radius: 50%;
  margin-right: 8px;
  text-align: center;
`
const Label = styled.div`

`
const LabelName = styled.span`
  font-size: 12px;
`
const Link = styled.a`
  display: block;
  font-size: 10px;
  color: ${palette.grey};
`
const Block = styled.div`
  display: flex;
  align-items: center;

  div${NumberLabel} {
    font-size: ${({size}) => size === 'large' ? '14px' : '12px'};

    width: ${({size}) => size === 'large' ? '30px' : '24px'};
    height: ${({size}) => size === 'large' ? '30px' : '24px'};
    line-height: ${({size}) => size === 'large' ? '30px' : '24px'};
  }

  span${LabelName} {
    font-size: ${({size}) => size === 'large' ? '14px' : '12px'};
    font-weight: ${({size}) => size === 'large' ? '500' : '700'};
  }
`

const CoinShort = (props) => {
    const title = getStateName(props.name)

    const dispatch = useDispatch()

    const hoverIn = () => {
        dispatch(setHoveredState(title))
        dispatch(setCoinImage(props.data.img))
    }
    const hoverOut = () => {
        dispatch(setHoveredState(null))
        dispatch(setCoinImage(''))
    }
    const selectCoin = () => {
        const tr = {
            actions: [{
                account: 'thehousegame',
                name: 'selectcoin',
                authorization: [{
                    actor: props.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    username: props.activeUser.accountName,
                    asset_id: props.asset_id
                },
            }],
        }
        const callback = async () => {
        }
        sendingTransaction(tr, dispatch, props.activeUser, callback)
    }

    return <Block
        size={props.size}
        onMouseMove={hoverIn}
        onMouseOut={hoverOut}
        onClick={!props.used ? selectCoin : () => {}}
        style={!props.used ? {cursor: 'pointer'} : {}}
    >
        <NumberLabel color={props.color}>{pointsPerRarity[props.data.rarity]}</NumberLabel>
        <Label>
            <LabelName>{title}</LabelName>
            <Link>{props.used && 'used'}</Link>
        </Label>
    </Block>
}

CoinShort.propTypes = {
    activeUser: PropTypes.object.isRequired,
    asset_id: PropTypes.string.isRequired,
    color: PropTypes.string,
    data: PropTypes.object,
    name: PropTypes.string,
    owner: PropTypes.string,
    size: PropTypes.oneOf(['large', 'small']),
    used: PropTypes.bool.isRequired,
}

export default CoinShort
