import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {getCoinImage, getNews} from '../../store/appSlice'
import {palette} from '../../utils/constants'
import {useWindowDimensions} from '../../utils/helpers'
import {device, size} from '../../utils/breakpoints'
import TickerShort from '../header/TickerShort'
import Tabs from './Tabs'

import IconClose from '../../assets/images/iconClose.svg'

const Block = styled.div`
  background: ${palette.darkBlue};
  border-radius: 20px;
  padding: 24px;

  @media ${device.tablet} {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    left: 0;
    padding: 150px 60px;
    background: rgba(7, 12, 47, 0.6);
    backdrop-filter: blur(4px)
  }

  @media ${device.mobileL} {
    padding: 50px 20px 20px;
  }
`
const StateRow = styled.dt`
  padding: 7px 24px;
  margin: 0 -24px;

  :hover {
    background: #131D53;
  }
`
const CloseBtn = styled.button`
  position: absolute;
  right: 60px;
  top: 70px;

  img {
    width: 35px;
  }

  @media ${device.onlyDesktop} {
    display: none;
  }

  @media ${device.mobileL} {
    right: 30px;
    top: 20px;
    img {
      width: 20px;
    }
  }
`
const List = styled.dl`
  overflow-y: auto;
  max-height: 80%;
  overflow-x: hidden;
`
const CoinImg = styled.img`
  height: 79%;
  display: block;
  margin: auto;
`

const News = ({closeMenu}) => {
    const coinImage = useSelector(getCoinImage)
    const news = useSelector(getNews)
    const {width} = useWindowDimensions()

    return <Block>
        <Tabs>
            <div label="News">
                <List>
                    {news.map(item => (
                        <StateRow key={item.asset_id}>
                            <TickerShort {...item} size='large'/>
                        </StateRow>
                    ))}
                </List>
            </div>
            {(width > size.tablet) ?
                <div label="Info">
                    {coinImage ? <CoinImg src={`https://ipfs.io/ipfs/${coinImage}`} alt=""/> : null}
                </div>
                :
                null
            }
        </Tabs>

        <CloseBtn onClick={() => closeMenu()}>
            <img src={IconClose}/>
        </CloseBtn>
    </Block>
}

News.propTypes = {
    closeMenu: PropTypes.func
}
export default News
