import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {device} from '../utils/breakpoints'
import {palette} from '../utils/constants'
import Main from './Main'
import Panels from './panels/Panels'

import IconList from '../assets/images/iconList.svg'
import IconNsm from '../assets/images/iconNsm.svg'

const Page = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: calc(100% - 60px);

  @media ${device.tablet} {
    padding: 0 16px 20px;
    height: 100%;
  }

  @media ${device.onlyDesktop} {

  }
`
const MobileButtons = styled.div`
  border-radius: 10px;
  background: ${palette.darkBlue};
  padding: 32px;
  position: absolute;
  bottom: 20px;
  right: 16px;

  @media ${device.mobileL} {
    padding: 14px;
  }

  @media ${device.onlyDesktop} {
    display: none;
  }

  @media screen and (max-width: 400px) {
    padding: 10px;
  }
`
const ButtonGroup = styled.div`
  text-align: center;

  span {
    display: block;
  }

  :first-child {
    margin-bottom: 32px;

    @media ${device.mobileL} {
      margin-bottom: 14px;
    }
  }
`
const Button = styled.button`
  background: ${palette.blue};
  border: 1px solid ${palette.darkGrey};
  border-radius: 50%;
  width: 56px;
  height: 56px;
  line-height: 0;
  transition: all .2s;

  :hover {
    background: ${palette.darkGrey};
  }

  img {
    width: 50%;
  }

  @media ${device.mobileL} {
    width: 30px;
    height: 30px;

    img {
      width: 70%;
    }
  }

  @media screen and (max-width: 400px) {
    img {
      width: 50%;
    }
  }
`

const Game = () => {
    const [isCoinOpen, setIsCoinOpen] = useState(false)
    const [isNewsOpen, setIsNewsOpen] = useState(false)

    const toogleCoin = () => {
        setIsNewsOpen(false)
        setIsCoinOpen(!isCoinOpen)
    }
    const toogleNews = () => {
        setIsCoinOpen(false);
        setIsNewsOpen(!isNewsOpen)
    }
    const closeMenu = () => {
        if (isCoinOpen) setIsCoinOpen(false)
        else {
            setIsNewsOpen(false)
        }
    }

    const mainPage = useMemo(() => {
        return <Main/>
    }, [])
    const panels = useMemo(() => {
        return <Panels
            isCoinOpen={isCoinOpen}
            isNewsOpen={isNewsOpen}
            closeMenu={closeMenu}
        />
    }, [])

    return <Page>
        {mainPage}
        {panels}
        <MobileButtons>
            <ButtonGroup>
                <Button onClick={toogleCoin}>
                    <img src={IconNsm}/>
                </Button>
                <span>Coins</span>
            </ButtonGroup>

            <ButtonGroup onClick={toogleNews}>
                <Button>
                    <img src={IconList}/>
                </Button>
                <span>News</span>
            </ButtonGroup>
        </MobileButtons>
    </Page>
}

export default Game
