export const topMenuHeight = 50
export const gameWidth = 1200
export const gameHeight = 800
export const borderTop = 0
export const borderBottom = 90
export const borderLeft = 10
export const borderRight = 0

export const TRANSACTION_EXPIRE = 60  //s
export const SLEEP_AFTER_TRANSACTION = 3000 //ms

export const palette = {
  text: "#ffffff",
  blue: "#1A1F37",
  darkBlue: "#070C2F",
  lightBlue: "#32D8E2",
  blueAccent: "#0075FF",
  grey: "#A0AEC0",
  darkGrey: "#4F5981",
  lightGrey: "#d5d5d5",
  orange: "#FCBF49",
  green: "#9ED628",
}

export const pointsPerRarity = {
  XF: 1,
  "XF Shine": 4,
  UNC: 4,
  "UNC Shine": 16,
  PROOF: 16,
  "PROOF Shine": 64,
}
export const partiesParams = {
  soc: {
    title: 'Socialists',
    color: 'green',
    hex: '#9ED628',
  },
  dem: {
    title: 'Democrats',
    color: 'blue',
    hex: '#32D8E2',
  },
  centr: {
    title: 'Centrists',
    color: 'purple',
    hex: '#480049',
  },
  rep: {
    title: 'Republicans',
    color: 'red',
    hex: '#9ED628',
  },
  lib: {
    title: 'Libertarians',
    color: 'yellow',
    hex: '#FCBF49',
  },
}

export const USA = {
  'Washington': {
    points: '100 35, 129 52, 127 69, 135 67, 139 31, 230 48, 222 115, 186 109, 147 111, 136 106, 128 109, 121 106, 121 96, 116 91, 103 86, 102 51, 99 45',
    seats: 10,
    seatsPosition: { x: 210, y: 60 },
    title: 'Washington',
    titlePosition: { x: 115, y: 83 },
  },
  'Idaho': {
    points: '233 49, 247 50, 243 72, 249 80, 248 86, 264 108, 269 108, 263 133, 267 137, 274 133, 276 140, 282 154, 286 155, 287 163, 315 164, 317 160, 319 160, 322 166, 316 223, 208 210, 214 168, 218 159, 216 156, 212 155, 212 154, 230 129, 231 124, 225 118, 225 111',
    seats: 2,
    seatsPosition: { x: 305, y: 180 },
    title: 'Idaho',
    titlePosition: { x: 240, y: 185 },
  },
  'Montana': {
    points: '250 51, 452 69, 449 160, 323 152, 322 164, 318 157, 315 158, 313 161, 290 162, 288 155, 285 153, 282 151, 283 146, 276 130, 273 130, 268 134, 265 132, 272 107, 267 106, 251 86, 252 80, 247 72',
    seats: 2,
    seatsPosition: { x: 438, y: 85 },
    title: 'Montana',
    titlePosition: { x: 320, y: 110 },
  },
  'Wyoming': {
    points: '325 155, 449 163, 445 257, 316 248',
    seats: 1,
    seatsPosition: { x: 435, y: 180 },
    title: 'Wyoming',
    titlePosition: { x: 345, y: 205 },
  },
  'North Dakota': {
    points: '455 70, 569 70, 582 139, 453 138',
    seats: 1,
    seatsPosition: { x: 560, y: 85 },
    title: 'North Dakota',
    titlePosition: { x: 465, y: 110 },
  },
  'Minnesota': {
    points: '572 70, 606 70, 606 60, 609 60, 613 73, 635 76, 646 76, 671 87, 679 82, 682 84, 699 86, 683 93, 658 119, 658 132, 651 137, 648 142, 648 146, 652 149, 651 166, 674 181, 680 184, 681 192, 589 197, 587 154, 580 148, 585 143, 585 139',
    seats: 8,
    seatsPosition: { x: 660, y: 100 },
    title: 'Minnesota',
    titlePosition: { x: 585, y: 100 },
  },
  'South Dakota': {
    points: '452 141, 583 142, 577 147, 581 154, 584 155, 586 197, 584 198, 586 206, 583 217, 574 212, 562 213, 560 215, 551 210, 450 208',
    seats: 1,
    seatsPosition: { x: 565, y: 155 },
    title: 'South Dakota',
    titlePosition: { x: 465, y: 180 },
  },
  'Wisconsin': {
    points: '660 120, 683 112, 682 119, 693 120, 696 124, 720 130, 732 130, 733 134, 738 135, 738 143, 744 149, 737 161, 740 164, 747 155, 749 156, 743 193, 747 210, 697 214, 695 211, 689 211, 685 201, 687 197, 683 193, 682 183, 654 165, 655 149, 650 143, 660 133',
    seats: 8,
    seatsPosition: { x: 720, y: 145 },
    title: 'Wisconsin',
    titlePosition: { x: 660, y: 160 },
  },
  'Michigan': {
    points: '695 118, 721 102, 725 111, 734 107, 744 115, 759 116, 769 108, 778 107, 784 104, 784 110, 798 110, 805 118, 792 119, 792 123, 793 127, 817 134, 820 144, 822 156, 814 167, 814 173, 816 176, 821 175, 830 163, 835 164, 844 186, 844 193, 838 194, 837 201, 833 205, 830 217, 807 221, 806 220, 771 225, 778 209, 777 195, 769 180, 769 170, 772 155, 779 146, 780 151, 783 153, 785 139, 793 135, 788 131, 793 127, 792 123, 782 118, 763 127, 753 128, 744 146, 743 141, 741 140, 740 133, 734 128, 699 122',
    seats: 13,
    seatsPosition: { x: 800, y: 150 },
    title: 'Michigan',
    titlePosition: { x: 770, y: 190 },
  },
  'New York': {
    points: '898 194, 912 178, 907 171, 907 168, 924 163, 943 162, 956 150, 950 138, 970 114, 996 107, 1003 140, 1007 139, 1014 195, 1012 199, 1013 203, 1017 203, 1026 199, 1035 198, 1017 209, 1012 208, 1016 206, 1017 203, 1013 203, 1011 203, 1011 201, 994 195, 988 193, 985 187, 979 183, 899 199',
    seats: 26,
    seatsPosition: { x: 980, y: 125 },
    title: 'New York',
    titlePosition: { x: 930, y: 178 },
  },
  'Vermont': {
    points: '998 107, 1027 99, 1030 109, 1024 114, 1022 132, 1022 154, 1012 156, 1008 137, 1005 137',
    seats: 1,
    seatsPosition: { x: 1010, y: 120 },
    title: 'Vermont',
    titlePosition: { x: 970, y: 90 },
  },
  'Maine': {
    points: '1037 90, 1045 76, 1046 51, 1054 32, 1056 32, 1057 36, 1061 36, 1070 29, 1079 33, 1088 65, 1095 66, 1097 73, 1103 74, 1106 82, 1083 97, 1080 93, 1076 98, 1076 108, 1065 112, 1055 137, 1050 131',
    seats: 2,
    seatsPosition: { x: 1068, y: 45 },
    title: 'Maine',
    titlePosition: { x: 1045, y: 80 },
  },
  'New Hampshire': {
    points: '1031 92, 1035 92, 1048 132, 1054 140, 1047 148, 1024 153, 1024 134, 1026 116, 1032 110, 1029 99',
    seats: 2,
    seatsPosition: { x: 1032, y: 120 },
    title: 'New Hampshire',
    titlePosition: { x: 970, y: 80 },
  },
  'Massachusetts': {
    points: '1012 158, 1048 151, 1053 145, 1057 149, 1053 157, 1054 160, 1061 159, 1068 169, 1068 171, 1066 171, 1066 168, 1064 168, 1057 175, 1050 165, 1013 173',
    seats: 9,
    seatsPosition: { x: 1040, y: 163 },
    title: 'Massachusetts',
    titlePosition: { x: 1065, y: 160 },
  },
  'Connecticut': {
    points: '1013 176, 1041 170, 1045 183, 1025 191, 1015 199, 1015 198, 1017 195',
    seats: 5,
    seatsPosition: { x: 1032, y: 183 },
    title: 'Connecticut',
    titlePosition: { x: 1020, y: 210 },
  },
  'Rhode Island': {
    points: '1044 169, 1049 168, 1051 181, 1047 183',
    seats: 2,
    seatsPosition: { x: 1045, y: 180 },
    title: 'Rhode Island',
    titlePosition: { x: 1050, y: 200 },
  },
  'Pennsylvania': {
    points: '885 204, 895 197, 897 202, 978 185, 982 188, 986 194, 991 197, 986 207, 989 210, 986 214, 990 218, 998 225, 989 235, 984 235, 981 239, 894 256',
    seats: 17,
    seatsPosition: { x: 967, y: 200 },
    title: 'Pennsylvania',
    titlePosition: { x: 889, y: 229 },
  },
  'New Jersey': {
    points: '994 198, 1009 203, 1012 218, 1011 235, 1004 253, 1003 253, 1003 248, 996 248, 990 244, 989 237, 1001 225, 989 215, 991 210, 989 206',
    seats: 12,
    seatsPosition: { x: 993, y: 215 },
    title: 'New Jersey',
    titlePosition: { x: 1015, y: 230 },
  },
  'Oregon': {
    points: '101 93, 109 92, 117 95, 119 107, 127 111, 136 109, 147 113, 187 112, 222 118, 228 124, 228 127, 211 151, 211 156, 215 159, 212 167, 205 209, 75 184, 74 165, 83 153',
    seats: 6,
    seatsPosition: { x: 210, y: 130 },
    title: 'Oregon',
    titlePosition: { x: 120, y: 155 },
  },
  'California': {
    points: '75 187, 150 202, 137 273, 227 385, 231 400, 235 404, 225 411, 225 419, 220 424, 220 434, 224 436, 222 440, 171 437, 168 423, 146 396, 139 396, 127 384, 109 379, 110 363, 91 330, 91 325, 96 322, 94 314, 86 308, 85 290, 69 261, 71 237, 64 226, 65 220, 72 213',
    seats: 52,
    seatsPosition: { x: 125, y: 215 },
    title: 'California',
    titlePosition: { x: 97, y: 335 },
  },
  'Nevada': {
    points: '153 202, 259 220, 243 358, 241 361, 237 358, 232 357, 228 359, 227 382, 140 273',
    seats: 4,
    seatsPosition: { x: 245, y: 235 },
    title: 'Nevada',
    titlePosition: { x: 170, y: 280 },
  },
  'Utah': {
    points: '263 220, 315 226, 313 250, 350 254, 343 348, 249 338',
    seats: 4,
    seatsPosition: { x: 335, y: 268 },
    title: 'Utah',
    titlePosition: { x: 280, y: 290 },
  },
  'Colorado': {
    points: '353 254, 483 260, 481 354, 346 348',
    seats: 8,
    seatsPosition: { x: 470, y: 275 },
    title: 'Colorado',
    titlePosition: { x: 380, y: 310 },
  },
  'Nebraska': {
    points: '450 211, 550 212, 559 217, 563 215, 573 215, 585 223, 588 223, 601 267, 609 280, 485 282, 485 257, 449 256',
    seats: 3,
    seatsPosition: { x: 570, y: 230 },
    title: 'Nebraska',
    titlePosition: { x: 490, y: 250 },
  },
  'Iowa': {
    points: '587 199, 682 194, 688 213, 693 213, 695 217, 704 225, 705 230, 702 236, 696 240, 691 241, 689 246, 692 251, 692 253, 689 260, 685 261, 685 266, 679 262, 604 266, 590 221, 586 216, 589 206',
    seats: 4,
    seatsPosition: { x: 670, y: 210 },
    title: 'Iowa',
    titlePosition: { x: 620, y: 235 },
  },
  'Illinois': {
    points: '697 216, 747 212, 747 217, 754 230, 760 285, 758 291, 763 300, 756 313, 753 328, 754 331, 747 334, 747 338, 749 341, 748 342, 740 339, 736 343, 732 344, 730 339, 731 337, 729 330, 711 319, 711 315, 714 303, 708 299, 704 301, 701 294, 689 284, 685 271, 687 267, 687 263, 690 262, 694 254, 694 250, 691 246, 692 243, 697 242, 704 237, 706 232, 707 224',
    seats: 17,
    seatsPosition: { x: 730, y: 228 },
    title: 'Illinois',
    titlePosition: { x: 695, y: 275 },
  },
  'Indiana': {
    points: '757 232, 762 231, 768 227, 804 223, 814 292, 809 295, 802 295, 804 300, 800 303, 800 306, 796 307, 796 313, 791 314, 787 310, 784 315, 784 317, 781 317, 778 316, 774 321, 768 319, 764 319, 761 321, 757 320, 758 314, 765 302, 764 295, 761 291, 763 286',
    seats: 9,
    seatsPosition: { x: 792, y: 238 },
    title: 'Indiana',
    titlePosition: { x: 750, y: 270 },
  },
  'Ohio': {
    points: '807 224, 830 220, 839 225, 850 225, 862 220, 883 205, 889 243, 886 260, 879 268, 876 267, 872 273, 871 280, 868 278, 865 282, 863 293, 860 294, 854 291, 852 287, 845 292, 841 290, 837 292, 833 290, 828 290, 822 284, 815 284',
    seats: 15,
    seatsPosition: { x: 868, y: 227 },
    title: 'Ohio',
    titlePosition: { x: 830, y: 260 },
  },
  'Kansas': {
    points: '485 285, 611 283, 616 286, 618 285, 619 286, 615 292, 622 302, 625 302, 627 353, 484 355',
    seats: 4,
    seatsPosition: { x: 600, y: 300 },
    title: 'Kansas',
    titlePosition: { x: 525, y: 325 },
  },
  'Missouri': {
    points: '605 269, 678 264, 683 269, 683 275, 686 284, 698 294, 702 303, 705 303, 707 302, 712 304, 708 316, 709 319, 726 331, 728 340, 731 347, 735 347, 735 355, 728 357, 726 371, 717 372, 721 366, 721 363, 717 359, 631 365, 627 301, 624 300, 618 293, 622 288, 622 285, 619 283, 615 283',
    seats: 8,
    seatsPosition: { x: 670, y: 280 },
    title: 'Missouri',
    titlePosition: { x: 635, y: 320 },
  },
  'Arizona': {
    points: '248 341, 342 351, 331 486, 291 483, 218 446, 220 443, 223 442, 226 439, 226 435, 222 433, 222 426, 228 419, 228 412, 237 406, 237 406, 233 399, 229 385, 231 361, 234 360, 238 363, 242 363, 246 359',
    seats: 9,
    seatsPosition: { x: 328, y: 365 },
    title: 'Arizona',
    titlePosition: { x: 250, y: 410 },
  },
  'New Mexico': {
    points: '345 351, 461 357, 457 477, 382 474, 382 479, 349 477, 348 488, 334 487',
    seats: 3,
    seatsPosition: { x: 447, y: 373 },
    title: 'New Mexico',
    titlePosition: { x: 355, y: 420 },
  },
  'Texas': {
    points: '386 477, 460 480, 463 370, 521 370, 521 417, 528 422, 537 422, 539 426, 560 428, 564 434, 567 434, 570 430, 578 434, 580 437, 582 437, 585 432, 597 438, 621 430, 637 439, 644 439, 645 477, 651 482, 650 487, 656 496, 657 504, 654 510, 655 523, 651 531, 642 535, 636 535, 635 530, 628 533, 631 542, 626 549, 606 559, 594 559, 597 566, 591 564, 583 573, 585 579, 575 579, 581 586, 575 592, 570 589, 571 596, 578 597, 575 604, 578 616, 582 626, 578 628, 575 624, 562 623, 544 615, 536 601, 534 587, 518 570, 494 533, 473 529, 464 533, 454 551, 428 536, 420 512',
    seats: 38,
    seatsPosition: { x: 620, y: 450 },
    title: 'Texas',
    titlePosition: { x: 510, y: 490 },
  },
  'Oklahoma': {
    points: '464 357, 627 356, 628 368, 633 393, 634 435, 621 428, 598 435, 585 429, 581 434, 579 431, 576 432, 571 429, 566 432, 561 425, 540 424, 536 417, 530 419, 524 414, 523 367, 464 367',
    seats: 5,
    seatsPosition: { x: 615, y: 370 },
    title: 'Oklahoma',
    titlePosition: { x: 540, y: 400 },
  },
  'Arkansas': {
    points: '631 367, 716 362, 719 365, 713 371, 713 375, 725 373, 725 376, 720 386, 720 393, 717 399, 712 404, 712 412, 704 422, 702 430, 704 446, 646 450, 646 437, 637 437, 635 392',
    seats: 4,
    seatsPosition: { x: 700, y: 377 },
    title: 'Arkansas',
    titlePosition: { x: 640, y: 400 },
  },
  'Kentucky': {
    points: '737 345, 740 341, 747 344, 750 344, 751 342, 749 337, 756 334, 757 331, 755 329, 761 323, 764 324, 768 321, 775 324, 779 319, 783 321, 788 314, 794 317, 798 314, 798 310, 806 300, 805 297, 809 297, 813 294, 816 294, 815 287, 821 286, 827 292, 833 292, 837 295, 841 292, 846 294, 852 290, 858 296, 858 303, 872 316, 861 326, 859 333, 844 341, 757 351, 758 355, 737 356',
    seats: 6,
    seatsPosition: { x: 845, y: 305 },
    title: 'Kentucky',
    titlePosition: { x: 760, y: 337 },
  },
  'Tennessee': {
    points: '730 359, 761 357, 760 353, 882 338, 883 341, 878 348, 875 348, 870 353, 867 352, 861 355, 861 359, 851 366, 847 367, 841 372, 841 375, 837 376, 837 383, 721 396, 723 392, 722 388, 726 379, 728 370',
    seats: 9,
    seatsPosition: { x: 865, y: 351 },
    title: 'Tennessee',
    titlePosition: { x: 750, y: 375 },
  },
  'Louisiana': {
    points: '647 452, 704 449, 705 458, 709 464, 707 468, 707 471, 701 476, 699 484, 699 489, 697 490, 698 498, 737 495, 735 501, 742 512, 736 511, 731 508, 725 514, 725 517, 731 520, 737 518, 744 523, 741 527, 755 538, 754 539, 739 530, 737 531, 732 528, 730 531, 734 533, 734 540, 733 540, 731 536, 727 534, 722 539, 714 539, 710 533, 711 531, 709 529, 707 532, 704 530, 695 524, 687 528, 689 532, 680 532, 667 528, 656 529, 658 523, 656 511, 660 503, 659 495, 653 486, 653 481, 648 475',
    seats: 6,
    seatsPosition: { x: 693, y: 463 },
    title: 'Louisiana',
    titlePosition: { x: 660, y: 508 },
  },
  'Alabama': {
    points: '764 393, 811 388, 833 451, 832 454, 831 463, 834 469, 834 479, 836 482, 783 488, 782 494, 787 498, 787 504, 783 504, 783 506, 779 503, 779 499, 775 495, 773 505, 769 505, 764 471',
    seats: 7,
    seatsPosition: { x: 803, y: 403 },
    title: 'Alabama',
    titlePosition: { x: 763, y: 451 },
  },
  'Mississippi': {
    points: '720 398, 761 394, 761 472, 767 506, 761 506, 757 504, 751 508, 748 506, 746 512, 744 512, 738 501, 740 493, 700 496, 700 491, 703 478, 709 472, 709 469, 713 464, 708 459, 704 431, 706 423, 708 419, 715 412, 714 405',
    seats: 4,
    seatsPosition: { x: 748, y: 410 },
    title: 'Mississippi',
    titlePosition: { x: 685, y: 450 },
  },
  'Georgia': {
    points: '813 388, 859 382, 857 387, 864 393, 868 393, 901 423, 905 433, 908 434, 911 438, 912 443, 917 445, 916 447, 914 448, 911 461, 908 462, 910 477, 902 476, 899 479, 901 487, 899 487, 897 484, 841 488, 836 477, 836 468, 833 463, 834 455, 836 452',
    seats: 14,
    seatsPosition: { x: 840, y: 397 },
    title: 'Georgia',
    titlePosition: { x: 838, y: 440 },
  },
  'Florida': {
    points: '785 490, 837 484, 840 490, 896 486, 898 490, 902 489, 901 481, 903 478, 911 480, 934 521, 932 521, 951 556, 951 559, 956 563, 959 569, 961 586, 958 605, 952 609, 948 608, 942 605, 935 595, 929 593, 924 589, 921 580, 923 577, 921 575, 917 579, 916 573, 918 569, 913 571, 910 569, 908 571, 902 562, 902 557, 906 552, 903 547, 896 546, 897 526, 891 519, 886 519, 878 512, 877 510, 862 500, 855 501, 841 513, 837 513, 834 507, 828 504, 829 501, 827 499, 820 502, 816 497, 807 497, 801 501, 797 496, 792 498, 792 502, 789 500, 788 496, 784 494',
    seats: 28,
    seatsPosition: { x: 905, y: 520 },
    title: 'Florida',
    titlePosition: { x: 862, y: 504 },
  },
  'North Carolina': {
    points: '885 338, 993 318, 995 321, 988 330, 984 329, 983 323, 981 324, 981 329, 983 335, 994 333, 995 331, 996 339, 1000 338, 1000 333, 1003 333, 1003 336, 997 345, 992 342, 988 342, 986 345, 980 344, 979 346, 981 348, 990 350, 989 354, 987 356, 982 353, 980 355, 985 359, 994 358, 995 360, 981 367, 977 364, 975 366, 976 371, 969 380, 969 387, 961 388, 958 390, 932 371, 910 375, 906 369, 902 368, 875 371, 862 379, 839 382, 839 378, 844 377, 844 372, 848 369, 852 369, 863 360, 867 354, 870 356, 876 350, 879 351, 883 344, 885 343',
    seats: 14,
    seatsPosition: { x: 960, y: 338 },
    title: 'North Carolina',
    titlePosition: { x: 865, y: 365 },
  },
  'South Carolina': {
    points: '859 386, 864 380, 876 373, 902 370, 906 372, 907 373, 909 377, 932 373, 956 391, 946 403, 945 408, 946 410, 944 414, 941 414, 938 421, 935 420, 933 422, 935 425, 929 430, 917 431, 919 439, 919 442, 914 442, 914 437, 909 432, 908 432, 902 421, 893 412, 887 409, 870 391, 864 390',
    seats: 7,
    seatsPosition: { x: 925, y: 387 },
    title: 'South Carolina',
    titlePosition: { x: 855, y: 400 },
  },
  'Virginia': {
    points: '851 340, 860 334, 864 327, 874 318, 880 322, 884 322, 888 319, 890 321, 898 317, 898 315, 908 311, 908 305, 912 297, 916 287, 916 283, 919 286, 924 285, 927 275, 929 276, 936 264, 937 258, 947 262, 948 258, 952 259, 953 262, 961 265, 963 268, 959 271, 959 280, 993 316',
    seats: 11,
    seatsPosition: { x: 940, y: 275 },
    title: 'Virginia',
    titlePosition: { x: 912, y: 310 },
  },
  'West Virginia': {
    points: '860 296, 865 295, 866 291, 868 298, 866 286, 868 281, 871 284, 874 280, 874 274, 877 270, 879 270, 888 262, 890 245, 893 259, 912 255, 914 268, 922 260, 925 259, 927 256, 933 256, 938 250, 943 253, 946 256, 946 260, 937 256, 934 257, 934 264, 928 273, 925 272, 922 283, 920 283, 915 280, 913 286, 911 296, 906 303, 906 310, 899 313, 896 313, 895 316, 890 318, 888 316, 883 321, 879 319, 875 314, 873 314, 860 303',
    seats: 2,
    seatsPosition: { x: 900, y: 270 },
    title: 'West Virginia',
    titlePosition: { x: 835, y: 290 },
  },
  'Alaska': {
    points: '143 536, 152 536, 158 531, 160 525, 168 522, 171 524, 175 518, 180 517, 184 512, 186 513, 186 519, 190 516, 194 519, 199 517, 201 521, 206 523, 213 521, 229 526, 239 523, 249 527, 269 634, 278 634, 279 632, 281 633, 284 638, 293 643, 294 646, 300 647,340 642, 301 636, 304 633, 304 639, 307 637, 323 648, 337 662, 349 664, 352 671, 351 677, 348 672, 337 664, 327 657, 318 647, 313 645, 307 637, 304 639, 303 638, 309 636, 305 645, 304 642, 300 642, 300 647, 301 647, 301 650, 297 648, 289 643, 283 642, 284 638, 281 633, 276 639, 271 638, 269 636, 254 639, 249 637, 250 631, 245 635, 240 631, 240 628, 237 628, 234 630, 231 626, 224 633, 224 635, 226 640, 225 642, 222 641, 211 652, 214 646, 209 647, 209 644, 212 641, 212 637, 215 634, 224 635, 224 633, 219 630, 223 626, 221 624, 216 628, 214 627, 203 640, 200 640, 199 642, 202 645, 198 645, 191 654, 193 657, 196 657, 198 659, 178 678, 163 687, 162 690, 154 691, 154 688, 158 685, 164 683, 165 679, 170 675, 174 675, 174 672, 173 671, 176 664, 178 657, 177 653, 171 658, 167 652, 163 658, 156 652, 149 656, 147 638, 151 634, 147 632, 142 642, 137 642, 132 633, 141 634, 139 627, 135 629, 130 623, 133 611, 137 613, 139 606, 142 602, 143 606, 150 603, 157 602, 160 599, 159 591, 162 584, 158 583, 151 587, 137 585, 133 583, 134 579, 137 577, 128 572, 140 566, 148 564, 145 568, 157 573, 166 568, 166 563, 159 564, 162 560, 157 556, 151 556, 150 550, 141 541',
    // points: '183 536, 192 536, 198 531, 200 525, 208 522, 211 524, 215 518, 220 517, 224 512, 226 513, 226 519, 230 516, 234 519, 239 517, 241 521, 246 523, 253 521, 269 526, 279 523, 289 527, 309 634, 318 634, 319 632, 321 633, 324 638, 333 643, 334 646, 340 647,340 642, 341 636, 344 633, 344 639, 347 637, 363 648, 377 662, 389 664, 392 671, 391 677, 388 672, 377 664, 367 657, 358 647, 353 645, 347 637, 344 639, 343 638, 349 636, 345 645, 344 642, 340 642, 340 647, 341 647, 341 650, 337 648, 329 643, 323 642, 324 638, 321 633, 316 639, 311 638, 309 636, 294 639, 289 637, 290 631, 285 635, 280 631, 280 628, 277 628, 274 630, 271 626, 264 633, 264 635, 266 640, 265 642, 262 641, 251 652, 254 646, 249 647, 249 644, 252 641, 252 637, 255 634, 264 635, 264 633, 259 630, 263 626, 261 624, 256 628, 254 627, 243 640, 240 640, 239 642, 242 645, 238 645, 231 654, 233 657, 236 657, 238 659, 218 678, 203 687, 202 690, 194 691, 194 688, 198 685, 204 683, 205 679, 210 675, 214 675, 214 672, 213 671, 216 664, 218 657, 217 653, 211 658, 207 652, 203 658, 196 652, 189 656, 187 638, 191 634, 187 632, 182 642, 177 642, 172 633, 181 634, 179 627, 175 629, 170 623, 173 611, 177 613, 179 606, 182 602, 183 606, 190 603, 197 602, 200 599, 199 591, 202 584, 198 583, 191 587, 177 585, 173 583, 174 579, 177 577, 168 572, 180 566, 188 564, 185 568, 197 573, 206 568, 206 563, 199 564, 202 560, 197 556, 191 556, 190 550, 181 541',
    seats: 1,
    seatsPosition: { x: 230, y: 544 },
    title: 'Alaska',
    titlePosition: { x: 180, y: 585 },
  },
  'Hawaii': {
    points: '303 531, 307 527, 314 528, 312 534, 345 544, 351 541, 355 548, 358 550, 370 555, 383 556, 387 559, 390 562, 396 561, 403 566, 408 580, 414 583, 422 585, 427 589, 428 594, 431 595, 436 599, 429 604, 423 605, 414 613, 408 611, 409 603, 404 593, 411 586, 408 583, 408 580, 403 566, 394 570, 392 565, 387 563, 386 561, 387 559, 383 556, 382 564, 379 565, 378 563, 379 563, 382 564, 383 556, 381 557, 371 556, 370 555, 358 550, 355 551, 351 548, 348 550, 345 544, 312 534, 307 534 ',
    // points: '453 681, 457 677, 464 678, 462 684, 495 694, 501 691, 505 698, 508 700, 520 705, 533 706, 537 709, 540 712, 546 711, 553 716, 558 730, 564 733, 572 735, 577 739, 578 744, 581 745, 586 749, 579 754, 573 755, 564 763, 558 761, 559 753, 554 743, 561 736, 558 733, 558 730, 553 716, 544 720, 542 715, 537 713, 536 711, 537 709, 533 706, 532 714, 529 715, 528 713, 529 713, 532 714, 533 706, 531 707, 521 706, 520 705, 508 700, 505 701, 501 698, 498 700, 495 694, 462 684, 457 684',
    seats: 2,
    seatsPosition: { x: 413, y: 600 },
    title: 'Hawaii',
    titlePosition: { x: 440, y: 610 },
  },
  'Maryland': {
    points: '914 255, 983 240, 990 271, 1001 269, 1001 271, 996 290, 994 291, 994 298, 992 300, 992 293, 995 282, 992 280, 989 273, 984 273, 981 272, 982 269, 980 260, 977 254, 978 249, 976 248, 970 254, 974 266, 978 274, 979 279, 972 278, 967 275, 964 277, 962 277, 962 274, 966 265, 955 261, 954 257, 949 256, 941 250, 935 250, 932 254, 927 253, 923 258, 921 258, 916 263',
    seats: 8,
    seatsPosition: { x: 960, y: 257 },
    title: 'Maryland',
    titlePosition: { x: 1000, y: 290 },
  },
  'Delaware': {
    points: '985 240, 985 237, 988 236, 988 246, 992 250, 992 254, 1000 260, 999 264, 1002 266, 991 268',
    seats: 1,
    seatsPosition: { x: 991, y: 264 },
    title: 'Delaware',
    titlePosition: { x: 1005, y: 267 },
  },
}
