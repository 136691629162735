import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette, partiesParams} from '../../utils/constants'

const NumberLabel = styled.div`
  border: ${props => `1px solid ${props.color}`};
  border-radius: 50%;
  margin-right: 8px;
  text-align: center;
`
const Label = styled.div`

`
const LabelName = styled.span`
  font-size: 12px;
`
const Link = styled.a`
  display: block;
  font-size: 8px;
  color: ${palette.grey};
`
const Block = styled.div`
  display: flex;
  align-items: center;

  div${NumberLabel} {
    font-size: ${({size}) => size === 'large' ? '14px' : '12px'};

    width: ${({size}) => size === 'large' ? '30px' : '24px'};
    height: ${({size}) => size === 'large' ? '30px' : '24px'};
    line-height: ${({size}) => size === 'large' ? '30px' : '24px'};
  }

  span${LabelName} {
    font-size: ${({size}) => size === 'large' ? '14px' : '12px'};
    font-weight: ${({size}) => size === 'large' ? '500' : '700'};
  }
`

const TickerShort = ({points, state, username, party, size = 'medium'}) => {
    return <Block size={size}>
        <NumberLabel color={partiesParams[party].hex}>{points}</NumberLabel>
        <Label>
            <LabelName>{state}</LabelName>
            <Link>{username}</Link>
        </Label>
    </Block>
}

TickerShort.propTypes = {
    points: PropTypes.node,
    state: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    party: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['large', 'small']),
}

export default TickerShort
