import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import Scoreboard from './canvas/Scoreboard'
import {device} from '../utils/breakpoints'
import StateInfo from './canvas/StateInfo'
import Switches from './canvas/Switches'
import Canvas from './canvas/Canvas'
import {getHoveredState} from '../store/appSlice'

const Block = styled.section`
  position: relative;
  width: 100%;

  @media ${device.onlyDesktop} {
    width: calc(100% - 330px);
  }
  @media ${device.tablet} {
    padding-top: 100px;
  }
  @media ${device.mobileL} {
    padding-top: 80px;
  }
`
const WrappCanvas = styled.div`
  @media ${device.tablet} {
    overflow: scroll;
    margin: 0 -15px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const Main = () => {
    const stateInfo = useSelector(getHoveredState)

    return <Block>
        <WrappCanvas id='wpaper-canvas'>
            <Canvas/>
        </WrappCanvas>
        <Switches/>
        <Scoreboard/>
        {stateInfo && <StateInfo {...stateInfo}/>}
    </Block>
}

export default Main
