import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'

const Header = styled.h3`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.18em;
  font-weight: 500;
  margin-bottom: 16px;
  text-transform: uppercase;
`
const Line = styled.span`
  display: block;
  border: 0;
  outline: 0;
  height: 1px;
  background: #fff;
  background: linear-gradient(to right, rgba(30, 87, 153, 0.1) 0%, rgb(255 255 255) 50%, rgba(125, 185, 232, 0.01) 100%);
  width: 100%;
  height: 1px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    width: 70%;
    margin: 0 auto 35px;
  }
`

const CoinTitle = (props) => {
    return <div>
        <Header>{props.children}</Header>
        <Line/>
    </div>
}

CoinTitle.propTypes = {
    children: PropTypes.string
}
export default CoinTitle
