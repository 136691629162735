import AtomicAssetsApi from '../api/atomicAssetsApi'
import * as waxjs from '@waxio/waxjs/dist'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'


const atomicApi = new AtomicAssetsApi()
const wax = new waxjs.WaxJS({
    rpcEndpoint: 'https://wax.greymass.com',
    tryAutoLogin: false,
})

const initialState = {
    userClaimBalance: null,
    userCoins: [],
    userGameBalance: null,
}

export const requestUserClaimBalance = createAsyncThunk(
    'account/requestUserClaimBalance',
    async (login) => {
        let result = await wax.rpc.get_table_rows({
            code: 'thehousegame',
            table: 'balance',
            scope: 'thehousegame',
            key_type: 'i64',
            lower_bound: login,
            upper_bound: login
        })
        return result.rows.length ? result.rows[0].balance / (10 ** 6) : null
    }
)
export const requestUserCoins = createAsyncThunk(
    'account/requestUserCoins',
    async (login) => {
        return await atomicApi.getUserCoins(login)
    }
)
export const requestUserGameBalance = createAsyncThunk(
    'account/requestUserGameBalance',
    async (login) => {
        let result = await wax.rpc.get_table_rows({
            code: 'thehousecoin',
            table: 'accounts',
            scope: login,
        })
        return result.rows.length ? result.rows[0].balance : null
    }
)

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setUserClaimBalance: (state, action) => {
            state.userClaimBalance = action.payload
        },
        setUserGameBalance: (state, action) => {
            state.userGameBalance = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestUserClaimBalance.fulfilled, (state, action) => {
            state.userClaimBalance = action.payload
        })
        builder.addCase(requestUserCoins.fulfilled, (state, action) => {
            state.userCoins = action.payload
        })
        builder.addCase(requestUserGameBalance.fulfilled, (state, action) => {
            state.userGameBalance = action.payload
        })
    },
})

export const getUserClaimBalance = (state) => state.account.userClaimBalance
export const getUserCoins = (state) => {
    let usedCoins = state.app.usedCoins.map(item => item.asset_id)
    let result = []
    for (let i = 0; i < state.account.userCoins.length; i++) {
        const item = state.account.userCoins[i]
        result.push({...item, used: usedCoins.indexOf(item.asset_id) >= 0})
    }
    return result
}
export const getUserGameBalance = (state) => state.account.userGameBalance

export default accountSlice.reducer
