import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'

const Block = styled.div`

`
const Value = styled.h6`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  display: block;

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`
const Heading = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 10px;
`

const Score = ({value, text}) => {
    return <Block>
        <Value>{value}</Value>
        <Heading>{text}</Heading>
    </Block>
}

Score.propTypes = {
    value: PropTypes.node,
    text: PropTypes.string,
}
export default Score
