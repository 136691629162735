import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {palette} from '../../utils/constants'

const ToogleWrap = styled.div`
  display: flex;
  align-items: center;
`
const Toogle = styled.label`
  display: block;
  width: 45px;
  height: 25px;
  cursor: pointer;
  position: relative;
  margin-right: 8px;

  input[type="checkbox"] {
    display: none;

    :checked + .toggler-slider {
      background-color: ${palette.lightBlue};
    }
  }

  input[type="checkbox"]:checked + .toggler-slider .toggler-knob {
    left: calc(100% - 25px);
    background-color: #fff;
  }
`
const Slider = styled.div`
  background-color: rgb(160 174 192 / 100%);
  position: absolute;
  border-radius: 100px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
`
const Knob = styled.div`
  position: absolute;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  width: calc(25px);
  height: calc(25px);
  border-radius: 50%;
  left: 0;
  top: 0;
  background-color: ${palette.darkGrey};
  box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
`
const Badge = styled.div`
  font-weight: 500;
`

const Toogler = (props) => {
    const handleChange = () => {
        props.setChecked(!props.checked)
    }

    return <ToogleWrap>
        <Toogle>
            <input type="checkbox"
                   checked={props.checked}
                   onChange={handleChange}
            />
            <Slider className='toggler-slider'>
                <Knob className='toggler-knob'/>
            </Slider>
        </Toogle>
        {props.label && <Badge>{props.label}</Badge>}
    </ToogleWrap>
}

Toogler.propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string,
    setChecked: PropTypes.func.isRequired,
}
export default Toogler
