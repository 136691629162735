import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import store from './store/store'
import App from './App'
import {UALProvider} from 'ual-reactjs-renderer'
import {Anchor} from "ual-anchor"
import {Wax} from '@eosdacio/ual-wax'
import reportWebVitals from './reportWebVitals'
import './index.css'

const waxChain = {
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    rpcEndpoints: [{
        protocol: 'https',
        host: 'wax.greymass.com',
        port: '443',
    }]
}
const anchor = new Anchor([waxChain], {
    appName: 'The House',
})
const wax = new Wax([waxChain], {})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <UALProvider appName={'The House'} chains={[waxChain]} authenticators={[wax, anchor]}>
                    <App/>
                </UALProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()