import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Ticker from './Ticker'
import Balance from './Balance'
import Navigation from './Navigation'
import {device, size} from '../../utils/breakpoints'
import {palette} from '../../utils/constants'

import LogoImg from '../../assets/images/logo.svg'
import IconUser from '../../assets/images/iconUser.svg'
import IconExit from '../../assets/images/iconExit.svg'
import IconLogin from '../../assets/images/iconLogin.svg'
import IconMenu from '../../assets/images/iconMenu.svg'

const Container = styled.header`
  display: grid;
  grid-template-columns: 150px calc(30%) 1fr 165px [buttonexit] 32px [buttonexitend] 32px [gumburgerend];
  gap: 20px;
  background: ${palette.darkBlue};
  padding: 12px;
  margin-bottom: 20px;

  @media screen and (min-width: ${+size.laptop + 1}px) {
    overflow: hidden;
    grid-template-columns: 150px 300px calc(30%) 1fr 165px [buttonexit] 32px [buttonexitend];
  }

  @media ${device.tablet} {
    padding: 8px 12px;
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
    grid-template-columns: 150px auto [buttonexit] 32px [gumburgerend];
  }

  @media ${device.mobileL} {
    grid-template-columns: 33px auto [buttonexit] 32px [gumburgerend];
    gap: 10px;
  }
`
const Logo = styled.a`
  font-weight: 700;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 0 10px;

  @media ${device.mobileL} {
    gap: 0;
    grid-template-columns: 1fr;
    img {
      height: 32px;
    }

    span {
      display: none;
    }
  }
`
const UserBlock = styled.div`
  margin-right: 24px;
  align-self: center;

  @media ${device.tablet} {
    display: none;
  }
`
const Avatar = styled.span`
  background: ${palette.blue};
  padding: 6px 8px;
  border-radius: 12px;
  margin-right: 8px;
`
const ButtonExit = styled.button`
  align-self: center;
  background-color: ${palette.blue};
  grid-column: buttonexit / buttonexitend;
  background-image: url(${IconExit});
  background-position: center;
  background-repeat: no-repeat;
  width: 31px;
  height: 31px;
  border-radius: 12px;
  border: none;
  transition: all .2s;
  cursor: pointer;

  :hover {
    background-color: ${palette.grey};
  }

  @media ${device.tablet} {
    display: none;
  }
`
const ButtonLogin = styled.button`
  background-color: ${palette.blue};
  background-image: url(${IconLogin});
  background-position: center;
  background-repeat: no-repeat;
  width: 31px;
  height: 31px;
  border-radius: 12px;
  border: none;
  transition: all .2s;
  cursor: pointer;

  :hover {
    background-color: ${palette.grey};
  }

  @media ${device.tablet} {
    display: none;
  }
`
const HamburgerButton = styled.button`
  background: url(${IconMenu});
  width: 30px;
  height: 30px;
  right: 16px;
  align-self: center;
  grid-column: buttonexitend / gumburgerend;

  @media screen and (min-width: ${+size.laptop + 1}px) {
    display: none;
  }

  @media ${device.tablet} {
    position: absolute;
  }
`

const useOutsideClick = (callback) => {
    const ref = React.useRef()

    React.useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener('click', handleClick, true)

        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [ref])

    return ref
}

const Header = (props) => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    const closeMenu = () => {
        setIsNavExpanded(false)
    }
    const ref = useOutsideClick(closeMenu)

    return <Container ref={ref}>
        <Logo>
            <img src={LogoImg}/>
            <span>THE HOUSE</span>
        </Logo>
        <Navigation ual={props.ual} expanded={isNavExpanded}/>
        <Ticker/>
        {props.ual.activeUser ? <Balance activeUser={props.ual.activeUser}/> : <div></div>}
        {props.ual.activeUser ? <UserBlock>
            <Avatar>
                <img src={IconUser}/>
            </Avatar>
            <span>{props.ual.activeUser.accountName}</span>
        </UserBlock> : <div></div>}
        {props.ual.activeUser ? <ButtonExit onClick={props.ual.logout}/> : <ButtonLogin onClick={props.ual.showModal}/>}
        <HamburgerButton onClick={() => setIsNavExpanded(!isNavExpanded)}/>
    </Container>
}

Header.propTypes = {
    ual: PropTypes.object.isRequired,
}

export default Header
