import AtomicAssetsApi from '../api/atomicAssetsApi'
import * as waxjs from '@waxio/waxjs/dist'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {pointsPerRarity, USA} from '../utils/constants'
import {getStateName} from '../utils/helpers'


const atomicApi = new AtomicAssetsApi()
const wax = new waxjs.WaxJS({
    rpcEndpoint: 'https://wax.greymass.com',
    tryAutoLogin: false,
})

const initialState = {
    assets: {},
    coinImage: '',
    filterState: null,
    hoveredState: null,
    loaded: false,
    news: [],
    showSeats: false,
    states: {...USA},
    usedCoins: [],
    users: {},
}

export const initialize = createAsyncThunk(
    'app/initialize',
    async (_, {dispatch}) => {
        await Promise.all([
            dispatch(requestUsedCoins()),
            dispatch(requestUsers()),
            /*
                        dispatch(requestParties()),
                        dispatch(requestGameData()),
                        dispatch(getReferrer()),
            */
        ])
        return true
    }
)
export const requestUsedCoin = createAsyncThunk(
    'app/requestUsedCoin',
    async (assetId) => {
        let result = await wax.rpc.get_table_rows({
            code: 'thehousegame',
            table: 'coins',
            scope: 'thehousegame',
            key_type: 'i64',
            lower_bound: assetId,
            upper_bound: assetId
        })
        return result.rows.length ? result.rows[0] : null
    }
)
export const requestUsedCoins = createAsyncThunk(
    'app/requestUsedCoins',
    async () => {
        let usedCoins = []
        let more = true
        let assetId = 0
        while (more) {
            let result = await wax.rpc.get_table_rows({
                code: 'thehousegame',
                table: 'coins',
                scope: 'thehousegame',
                key_type: 'i64',
                lower_bound: assetId,
            })
            usedCoins.push(...result.rows)
            more = result.more
            if (more) {
                assetId = result.next_key
            }
        }
        return usedCoins
    }
)
/*
export const requestUserParty = createAsyncThunk(
    'app/requestUserParty',
    async (login) => {
        let result = await wax.rpc.get_table_rows({
            code: 'thehousegame',
            table: 'users',
            scope: 'thehousegame',
            key_type: 'i64',
            lower_bound: login,
            upper_bound: login,
        })
        return result.rows.length ? result.rows[0].party_name : null
    }
)
*/
export const requestUsers = createAsyncThunk(
    'app/requestUsers',
    async () => {
        let users = {}
        let more = true
        let username = 0
        while (more) {
            let result = await wax.rpc.get_table_rows({
                code: 'thehousegame',
                table: 'users',
                scope: 'thehousegame',
                key_type: 'i64',
                lower_bound: username,
            })
            for (let i in result.rows) {
                let item = result.rows[i]
                users[item.user_name] = item.party_name
            }
            more = result.more
            if (more) {
                username = result.next_key
            }
        }
        return users
    }
)
export const updateAsset = createAsyncThunk(
    'app/updateAsset',
    async (id) => {
        return await atomicApi.getAsset(id)
    }
)

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        addNews: (state, action) => {
            state.news = [...state.news]
            state.news.push(action.payload)
        },
        countVotes: (state) => {
            for (let i = 0; i < state.usedCoins.length; i++) {
                const item = state.usedCoins[i]
                const currentState = state.states[item.state]
                const partyName = state.users[item.user_name]
                if (!currentState.votes) {
                    currentState.votes = {}
                }
                if (currentState.votes[partyName]) {
                    currentState.votes[partyName] += item.points
                } else {
                    currentState.votes[partyName] = item.points
                }
            }
        },
        setCoinImage: (state, action) => {
            state.coinImage = action.payload
        },
        setFilterState: (state, action) => {
            state.filterState = action.payload
        },
        setHoveredState: (state, action) => {
            state.hoveredState = action.payload
        },
        setShowSeats: (state, action) => {
            state.showSeats = action.payload
        },
        /*
                setLoaded: (state, action) => {
                    state.loaded = action.payload
                },
        */
    },
    extraReducers: (builder) => {
        builder.addCase(initialize.fulfilled, (state, action) => {
            state.loaded = action.payload
        })
        builder.addCase(requestUsedCoin.fulfilled, (state, action) => {
            state.usedCoins = [...state.usedCoins, action.payload]
            state.states = {...state.states}
            let partyName = state.users[action.payload.user_name]
            if (!state.states[action.payload.state].votes) {
                state.states[action.payload.state].votes = {}
                state.states[action.payload.state].votes[partyName] = action.payload.points
            } else {
                if (state.states[action.payload.state].votes[partyName]) {
                    state.states[action.payload.state].votes[partyName] += action.payload.points
                } else {
                    state.states[action.payload.state].votes[partyName] = action.payload.points
                }
            }
        })
        builder.addCase(requestUsedCoins.fulfilled, (state, action) => {
            state.usedCoins = action.payload
        })
        builder.addCase(requestUsers.fulfilled, (state, action) => {
            state.users = action.payload
        })
        builder.addCase(updateAsset.fulfilled, (state, action) => {
            state.assets = {...state.assets}
            state.assets[action.payload.asset_id] = action.payload
        })
        /*
                builder.addCase(requestUserParty.fulfilled, (state, action) => {
                    state.userGameParty = action.payload
                })
        */
    },
})

export const getCoinImage = (state) => state.app.coinImage
export const getFilterState = (state) => state.app.filterState
export const getHoveredState = (state) => state.app.states[state.app.hoveredState]
export const getNews = (state) => {
    let result = []
    for (let i = 0; i < state.app.news.length; i++) {
        let item = state.app.news[i]
        if (state.app.assets[item.asset_id]) {
            result.push({
                ...item,
                points: pointsPerRarity[state.app.assets[item.asset_id].data.rarity],
                state: getStateName(state.app.assets[item.asset_id].data.name),
                party: state.app.users[item.username],
            })
        }
    }
    return result
}
export const getShowSeats = (state) => state.app.showSeats
export const getStates = (state) => state.app.states
export const getTotalVotes = (state) => {
    let result = 0
    for (let i = 0; i < state.app.usedCoins.length; i++) {
        result += state.app.usedCoins[i].points
    }
    return result
}
export const getUsers = (state) => state.app.users

export const {addNews, countVotes, setHoveredState, setFilterState, setShowSeats, setCoinImage} = appSlice.actions

export default appSlice.reducer

