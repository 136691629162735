import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Title = styled.dt`
  text-decoration: ${({active}) => active ? 'underline' : 'none'};
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  :not(:last-of-type) {
    margin-right: 24px;
  }
`

const Tab = ({activeTab, label, onClick}) => {
    return <Title onClick={onClick} active={activeTab === label}>
        {label}
    </Title>
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}
export default Tab
